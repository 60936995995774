import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Paper,
  Box,
  useMediaQuery,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Chip,
  Button,
} from "@mui/material";
import { IoClose } from "react-icons/io5";
import {
  getTournament,
  registerForTournament,
  addTeamsToTournament,
  startTournament,
  leaveTournament,
} from "../../api/tournaments";
import { getUserTeamsDetailed } from "../../api/user";
import useMiddleware from "../../api/useMiddleware";
import {
  StoreContext,
  StoreDispatch,
  SET_ERRORS,
  SET_SUCCESSES,
  SET_SEARCH_USER,
} from "../../store/Store";
import {
  text,
  offWhite,
  doubtedBlue,
  menuShadow,
  card,
  cardDark,
  cardVeryLight,
  secondaryText,
  green,
  yellow,
  red,
} from "../../utils/themeContstants";
import { GiTrophy, GiPodium } from "react-icons/gi";
import { BiMoney } from "react-icons/bi";
import {
  FaUsers,
  FaGamepad,
  FaMapMarkerAlt,
  FaMedal,
  FaRegClock,
} from "react-icons/fa";
import { format, formatDistanceToNow, isPast } from "date-fns";
import TournamentBracket from "./TournamentBracket";
import CustomDropdown from "../../components/custom/CustomDropdown";
import PrimaryButton from "../../components/custom/PrimaryButton";
import Badge from "../../components/custom/Badge";
import Avatar from "../../avatar/Avatar";
import CustomInput from "../custom/CustomInput";
import { gameModeOptions } from "../../utils/options";
import { AccountTypeEnum } from "../../utils/enums";
import SecondaryButton from "../custom/SecondaryButton";
import CustomModal from "../custom/CustomModal";

const AddTeamsDialog = ({
  open,
  onClose,
  teams,
  onAddTeam,
  onRemoveTeam,
  onSubmit,
  newTeamName,
  onTeamNameChange,
  isAdding,
  styles,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    sx={styles.dialog}
    maxWidth="sm"
    fullWidth
  >
    <DialogTitle sx={styles.dialogTitle}>
      Add Teams to Tournament
      <IconButton aria-label="close" onClick={onClose} sx={styles.closeButton}>
        <IoClose />
      </IconButton>
    </DialogTitle>

    <DialogContent sx={styles.dialogContent}>
      <Box sx={styles.teamInputBox}>
        <Typography sx={styles.subLabel}>Add Team</Typography>
        <Box sx={styles.teamInputWrapper}>
          <CustomInput
            placeholder="Enter team name"
            value={newTeamName}
            onChange={onTeamNameChange}
            fullWidth
          />
          <PrimaryButton
            label="Add"
            onClick={onAddTeam}
            disabled={!newTeamName}
            color={doubtedBlue}
            sx={{ minWidth: "100px" }}
          />
        </Box>

        <Box sx={{ mt: 3 }}>
          <Typography sx={styles.subLabel}>Added Teams</Typography>
          <Box sx={styles.teamChipsContainer}>
            {teams.map((team, index) => (
              <Chip
                key={index}
                label={team}
                onDelete={() => onRemoveTeam(team)}
                variant="outlined"
                sx={styles.teamChip}
              />
            ))}
            {teams.length === 0 && (
              <Typography sx={{ color: secondaryText, fontSize: 14 }}>
                No teams added yet
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        <PrimaryButton
          label={isAdding ? "Adding Teams..." : "Submit Teams"}
          onClick={onSubmit}
          disabled={isAdding || teams.length === 0}
          fullWidth
          color={doubtedBlue}
        />
      </Box>
    </DialogContent>
  </Dialog>
);

const TournamentCountdown = ({ startTime, onCountdownEnd }) => {
  const [timeLeft, setTimeLeft] = useState("");
  const [hasEnded, setHasEnded] = useState(false);

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const start = new Date(startTime);
      const diffInSeconds = Math.floor((start - now) / 1000);

      if (diffInSeconds <= 0) {
        setTimeLeft("Tournament has started");
        if (!hasEnded) {
          setHasEnded(true);
          if (onCountdownEnd) {
            onCountdownEnd();
          }
        }
        return;
      }

      if (diffInSeconds < 60) {
        setTimeLeft(
          `Starts in ${diffInSeconds} second${diffInSeconds === 1 ? "" : "s"}`
        );
      } else {
        setTimeLeft(formatDistanceToNow(start, { addSuffix: true }));
      }
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [startTime, onCountdownEnd, hasEnded]);

  const formattedDate = format(new Date(startTime), "MMMM d, yyyy 'at' h:mm a");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        marginTop: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          backgroundColor: "rgba(255, 255, 255, 0.03)",
          padding: "12px 20px",
          borderRadius: "12px",
          border: `1px solid ${cardVeryLight}20`,
        }}
      >
        <Box
          sx={{
            backgroundColor: doubtedBlue,
            padding: "8px",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FaRegClock size={20} color={text} />
        </Box>
        <Box>
          <Typography
            sx={{
              color: doubtedBlue,
              fontSize: 16,
              fontWeight: 700,
              marginBottom: "2px",
            }}
          >
            {timeLeft}
          </Typography>
          <Typography
            sx={{
              color: secondaryText,
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {formattedDate}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const LeaveConfirmationDialog = ({ open, onClose, onConfirm, isLeaving }) => (
  <CustomModal
    open={open}
    onClose={onClose}
    title="Leave Tournament"
    maxWidth="sm"
  >
    <Box sx={{ textAlign: "center", py: 2 }}>
      <Typography
        sx={{
          color: text,
          fontSize: 18,
          fontWeight: 600,
          mb: 2,
        }}
      >
        Are you sure you want to leave this tournament?
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "center",
        }}
      >
        <SecondaryButton
          label="Cancel"
          onClick={onClose}
          color={cardVeryLight}
          sx={{ minWidth: "120px" }}
        />
        <SecondaryButton
          label={isLeaving ? "Leaving..." : "Leave Tournament"}
          onClick={onConfirm}
          disabled={isLeaving}
          bg={doubtedBlue}
          sx={{ minWidth: "120px" }}
        />
      </Box>
    </Box>
  </CustomModal>
);

const TournamentDetail = () => {
  const { id } = useParams();
  const [tournament, setTournament] = useState(null);
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [registering, setRegistering] = useState(false);
  const [showAddTeams, setShowAddTeams] = useState(false);
  const [newTeams, setNewTeams] = useState([]);
  const [newTeamName, setNewTeamName] = useState("");
  const [addingTeams, setAddingTeams] = useState(false);
  const [startingTournament, setStartingTournament] = useState(false);
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const middleware = useMiddleware();
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const isTablet = useMediaQuery("(max-width:1024px)");

  const handleGetTeams = () => {
    getUserTeamsDetailed(middleware, tournament.team_size).then((res) => {
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        console.log("Teams received:", res?.teams);
        const teamOptions = res?.teams?.map((team) => ({
          title: team.name,
          value: team,
          _id: team._id,
        }));
        setTeams(teamOptions);
      }
    });
  };

  const handleOpenProfile = (user_id) => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const handleRegister = () => {
    if (!selectedTeam) {
      updateStore({ type: SET_ERRORS, payload: "Please select a team" });
      return;
    }

    setRegistering(true);
    registerForTournament(middleware, id, selectedTeam._id).then((res) => {
      setRegistering(false);
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        setTournament(res);
        updateStore({
          type: SET_SUCCESSES,
          payload: "Successfully registered for tournament",
        });
      }
    });
  };

  const handleAddTeam = () => {
    if (newTeamName && !newTeams.includes(newTeamName)) {
      setNewTeams([...newTeams, newTeamName]);
      setNewTeamName("");
    }
  };

  const handleRemoveTeam = (teamToRemove) => {
    setNewTeams(newTeams.filter((team) => team !== teamToRemove));
  };

  const handleSubmitTeams = async () => {
    if (newTeams.length === 0) return;

    setAddingTeams(true);
    const res = await addTeamsToTournament(middleware, id, newTeams);
    setAddingTeams(false);

    if (res?.error) {
      updateStore({ type: SET_ERRORS, payload: res?.message });
    } else {
      setTournament(res);
      setNewTeams([]);
      setShowAddTeams(false);
      updateStore({ type: SET_SUCCESSES, payload: "Teams added successfully" });
    }
  };

  const handleStartTournament = async () => {
    setStartingTournament(true);
    const res = await startTournament(middleware, id);
    setStartingTournament(false);

    if (res?.error) {
      updateStore({ type: SET_ERRORS, payload: res?.message });
    } else {
      setTournament(res);
      updateStore({
        type: SET_SUCCESSES,
        payload: "Tournament started successfully",
      });
    }
  };

  const handleLeaveTournament = async () => {
    setIsLeaving(true);
    const res = await leaveTournament(middleware, id);
    setIsLeaving(false);

    if (res?.error) {
      updateStore({ type: SET_ERRORS, payload: res?.message });
    } else {
      setTournament(res);
      setShowLeaveConfirmation(false);
      updateStore({
        type: SET_SUCCESSES,
        payload: "Successfully left the tournament",
      });
    }
  };

  useEffect(() => {
    if (store?.user && !teams && tournament) {
      handleGetTeams();
    }
  }, [store?.user, tournament]);

  useEffect(() => {
    if (loading) {
      getTournament(middleware, id).then((res) => {
        setLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          setTournament(res);
        }
      });
    }
  }, [loading, id]);

  const styles = {
    container: {
      width: "100%",
      minHeight: "100vh",
      position: "relative",
      padding: isTablet ? "12px" : "24px",
      backgroundColor: "rgb(26, 44, 57)",
    },
    header: {
      fontSize: isTablet ? 28 : 36,
      fontWeight: 800,
      color: text,
      textTransform: "uppercase",
      letterSpacing: 0.5,
    },
    subHeader: {
      fontSize: 16,
      color: secondaryText,
      fontWeight: 500,
    },
    paper: {
      backgroundColor: card,
      padding: isTablet ? "16px" : "24px",
      boxShadow: menuShadow,
      borderRadius: 2,
      border: `1px solid ${cardVeryLight}`,
    },
    statLabel: {
      fontSize: 14,
      color: secondaryText,
      fontWeight: 600,
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    statValue: {
      fontSize: 16,
      color: text,
      fontWeight: 700,
    },
    subLabel: {
      fontSize: 14,
      fontWeight: 600,
      color: secondaryText,
      marginBottom: 1,
    },
    width: {
      width: "100%",
    },
    statusChip: {
      padding: "4px 12px",
      borderRadius: "12px",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 14,
      fontWeight: 600,
      color: text,
    },
    playerCard: {
      backgroundColor: cardDark,
      padding: "12px",
      borderRadius: "8px",
      border: `1px solid ${cardVeryLight}`,
      display: "flex",
      alignItems: "center",
      gap: "12px",
      transition: "all 0.2s ease",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: card,
        transform: "translateY(-2px)",
      },
    },
    dialog: {
      "& .MuiDialog-paper": {
        backgroundColor: cardDark,
        borderRadius: 2,
        maxWidth: 500,
        width: "100%",
      },
    },
    dialogTitle: {
      padding: "20px 24px",
      color: text,
      fontSize: 24,
      fontWeight: 700,
      borderBottom: `1px solid ${cardVeryLight}`,
    },
    dialogContent: {
      padding: "24px",
      backgroundColor: cardDark,
    },
    closeButton: {
      position: "absolute",
      right: 8,
      top: 8,
      color: secondaryText,
    },
    teamInputBox: {
      backgroundColor: cardVeryLight,
      borderRadius: 1,
      padding: 2,
    },
    teamInputWrapper: {
      display: "flex",
      gap: 2,
      alignItems: "center",
      marginTop: 1,
    },
    teamChipsContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: 1,
      marginTop: 1,
      minHeight: 100,
      backgroundColor: cardDark,
      borderRadius: 1,
      padding: 2,
    },
    teamChip: {
      backgroundColor: cardDark,
      color: text,
      borderColor: cardVeryLight,
      "&:hover": {
        backgroundColor: card,
      },
    },
    submitButton: {
      marginTop: 3,
    },
    prizeCard: {
      backgroundColor: cardDark,
      padding: "16px",
      borderRadius: "8px",
      border: `1px solid ${cardVeryLight}`,
      transition: "all 0.2s ease",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 1,
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        transform: "translateY(-2px)",
        boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
      },
    },
    prizeIcon: {
      fontSize: "24px",
      color: yellow,
    },
    prizeAmount: {
      fontSize: "20px",
      fontWeight: 700,
      color: text,
    },
    prizePosition: {
      fontSize: "14px",
      color: secondaryText,
      fontWeight: 600,
      display: "flex",
      alignItems: "center",
      gap: 1
    },
    prizePercentage: {
      fontSize: "12px",
      color: secondaryText,
      fontStyle: "italic",
    },
    qualifiedText: {
      color: green,
      fontSize: "14px",
      fontWeight: 600
    },
  };

  const additionalStyles = {
    teamInput: {
      display: "flex",
      gap: 1,
      marginBottom: 1,
    },
    teamChips: {
      display: "flex",
      flexWrap: "wrap",
      gap: 1,
      marginTop: 1,
    },
  };

  const renderPrizeStructure = () => {
    if (!tournament?.prize_structure) return null;

    const getPrizeIcon = (position) => {
      switch (position) {
        case 1:
          return (
            <GiTrophy style={{ color: yellow, fontSize: isTablet ? 24 : 32 }} />
          );
        case 2:
          return (
            <FaMedal
              style={{ color: offWhite, fontSize: isTablet ? 22 : 28 }}
            />
          );
        case 3:
          return (
            <FaMedal
              style={{ color: "#CD7F32", fontSize: isTablet ? 22 : 28 }}
            />
          );
        default:
          return (
            <GiPodium
              style={{ color: secondaryText, fontSize: isTablet ? 20 : 24 }}
            />
          );
      }
    };

    // Sort prizes by place
    const sortedPrizes = [...tournament.prize_structure].sort(
      (a, b) => a.place - b.place
    );

    return (
      <Grid item xs={12}>
        <Box
          sx={{
            width: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            spacing={isTablet ? 1 : 2}
            sx={{
              maxWidth: isTablet ? "100%" : "1200px",
              margin: "0",
              padding: isTablet ? 1 : 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {sortedPrizes.map((prize, index) => (
              <Grid
                item
                xs={6}
                sm={6}
                md={4}
                lg={3}
                key={prize._id}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    ...styles.prizeCard,
                    padding: isTablet ? "12px" : "16px",
                    minWidth: 0,
                    width: "100%",
                    maxWidth: isTablet ? "160px" : "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: isTablet ? 1 : 2,
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minWidth: isTablet ? "36px" : "48px",
                        flexShrink: 0,
                      }}
                    >
                      {getPrizeIcon(prize.place)}
                    </Box>
                    <Box
                      sx={{
                        minWidth: 0,
                        overflow: "hidden",
                        flex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          ...styles.prizePosition,
                          fontSize: isTablet ? "12px" : "14px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: isTablet ? "center" : "left",
                        }}
                      >
                        {prize.place === 1
                          ? "1st Place"
                          : prize.place === 2
                          ? "2nd Place"
                          : prize.place === 3
                          ? "3rd Place"
                          : `${prize.place}th Place`}
                        {tournament.name.toLowerCase().includes('faxuty') && 
                         !tournament.name.toLowerCase().includes('finals') && 
                         prize.place <= 3 && (
                          <Typography component="span" sx={styles.qualifiedText}>+ Qualified</Typography>
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          ...styles.prizeAmount,
                          fontSize: isTablet ? "16px" : "20px",
                          textAlign: isTablet ? "center" : "left",
                        }}
                      >
                        ${(prize.amount * tournament.team_size).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    );
  };

  if (loading) {
    return (
      <Grid container sx={styles.container} spacing={3}>
        <Grid item xs={12}>
          <Skeleton
            variant="text"
            width={300}
            height={60}
            sx={{ bgcolor: cardVeryLight }}
          />
        </Grid>
        <Grid item xs={12}>
          <Skeleton
            variant="rectangular"
            height={200}
            sx={{ bgcolor: cardVeryLight, borderRadius: 2 }}
          />
        </Grid>
      </Grid>
    );
  }

  if (!tournament) return null;

  const isRegistered = tournament.registered_players.some(
    (player) => player._id === store?.user?._id
  );

  const canRegister =
    (tournament.status === "REGISTRATION" || tournament.status === "PENDING") &&
    !isRegistered &&
    tournament.registered_teams.length < tournament.max_players;

  const getStatusColor = (status) => {
    switch (status) {
      case "REGISTRATION":
        return green;
      case "IN_PROGRESS":
        return yellow;
      case "COMPLETED":
        return red;
      default:
        return offWhite;
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "PENDING":
        return "Registration Open";
      case "REGISTRATION":
        return "Registration Open";
      case "IN_PROGRESS":
        return "Tournament Live";
      case "COMPLETED":
        return "Tournament Ended";
      default:
        return status;
    }
  };

  return (
    <Box sx={styles.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              mb: 2,
            }}
          >
            <Box>
              <Typography sx={styles.header}>
                {tournament?.name || <Skeleton width={300} />}
              </Typography>
              {tournament.start_time && tournament.status !== "COMPLETED" && (
                <TournamentCountdown
                  startTime={tournament.start_time}
                  onCountdownEnd={() => {
                    if (tournament.status === "PENDING") {
                      getTournament(middleware, id).then((res) => {
                        if (res?.error) {
                          updateStore({
                            type: SET_ERRORS,
                            payload: res?.message,
                          });
                        } else {
                          setTournament(res);
                        }
                      });
                    }
                  }}
                />
              )}
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              {store?.user?.account_type >= AccountTypeEnum.SENIOR_ADMIN &&
                tournament?.status === "PENDING" && (
                  <PrimaryButton
                    label={
                      startingTournament ? "Starting..." : "Start Tournament"
                    }
                    onClick={handleStartTournament}
                    disabled={
                      startingTournament ||
                      tournament?.registered_teams?.length < 2
                    }
                    color={green}
                  />
                )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography sx={styles.statLabel}>
                      <BiMoney size={18} />
                      Prize Pool
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.statValue}>
                      ${tournament.prize_pool.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography sx={styles.statLabel}>
                      <FaUsers size={16} />
                      Teams
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.statValue}>
                      {tournament.registered_teams.length} /{" "}
                      {tournament.max_players}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography sx={styles.statLabel}>
                      <FaUsers size={16} />
                      Team Size
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.statValue}>
                      {tournament.team_size}V{tournament.team_size}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography sx={styles.statLabel}>
                      <FaGamepad size={16} />
                      Game Mode
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.statValue}>
                      {
                        gameModeOptions.find(
                          (option) =>
                            option.value === tournament.game_match_type
                        )?.title
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography sx={styles.statLabel}>
                      <FaMapMarkerAlt size={16} />
                      Region
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography sx={styles.statValue}>
                      {tournament.region.toUpperCase()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {store?.user?.account_type >= AccountTypeEnum.SENIOR_ADMIN && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 1,
                    }}
                  >
                    <Typography sx={styles.subLabel}>
                      Tournament Teams
                    </Typography>
                    {(tournament.status === "PENDING" ||
                      tournament.status === "REGISTRATION") && (
                      <Button
                        variant="contained"
                        onClick={() => setShowAddTeams(true)}
                        sx={styles.addButton}
                      >
                        Add Teams
                      </Button>
                    )}
                  </Box>
                  <Box sx={styles.teamChipsContainer}>
                    {tournament.registered_teams.map((team, index) => (
                      <Chip
                        key={index}
                        label={team.name}
                        variant="outlined"
                        sx={styles.teamChip}
                      />
                    ))}
                    {tournament.registered_teams.length === 0 && (
                      <Typography sx={{ color: secondaryText, fontSize: 14 }}>
                        No teams registered yet
                      </Typography>
                    )}
                  </Box>
                </Grid>
              )}
              {renderPrizeStructure()}
            </Grid>
          </Paper>
        </Grid>

        {canRegister && (
          <Grid item xs={12}>
            <Paper sx={styles.paper}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography sx={{ ...styles.statValue, marginBottom: 1 }}>
                    Register for Tournament
                  </Typography>
                  <Typography sx={styles.subHeader}>
                    Entry Fee: ${tournament.entry_fee.toFixed(2)}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography sx={styles.subLabel}>Select your team</Typography>
                  <CustomDropdown
                    options={teams || []}
                    placeholder="Select team"
                    onChange={(value) => {
                      console.log("Selected team:", value);
                      setSelectedTeam(value);
                    }}
                    backgroundColor={cardDark}
                    borderColor={cardVeryLight}
                  />
                </Grid>

                <Grid item>
                  <PrimaryButton
                    label={registering ? "Registering..." : "Register Now"}
                    onClick={handleRegister}
                    disabled={registering || !selectedTeam}
                    fullWidth
                    color={doubtedBlue}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        {isRegistered &&
          tournament.status !== "IN_PROGRESS" &&
          tournament.status !== "COMPLETED" && (
            <Grid item xs={12}>
              <Paper sx={styles.paper}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography sx={{ ...styles.statValue, marginBottom: 1 }}>
                      Tournament Registration
                    </Typography>
                    <Typography sx={styles.subHeader}>
                      You are registered for this tournament
                    </Typography>
                  </Grid>

                  <Grid item>
                    <PrimaryButton
                      label="Leave Tournament"
                      onClick={() => setShowLeaveConfirmation(true)}
                      fullWidth
                      color={red}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

        {(tournament.status === "PENDING" ||
          tournament.status === "REGISTRATION") && (
          <Grid item xs={12}>
            <Paper sx={styles.paper}>
              <Typography sx={{ ...styles.statValue, marginBottom: 3 }}>
                Registered Players ({tournament.registered_players.length})
              </Typography>
              <Grid container spacing={2}>
                {tournament.registered_players.map((player) => (
                  <Grid item xs={12} sm={6} md={4} key={player._id}>
                    <Box
                      sx={styles.playerCard}
                      onClick={() => {
                        handleOpenProfile(player?._id);
                      }}
                    >
                      <Avatar
                        size={40}
                        avatar={player.new_avatar}
                        bgColor={cardVeryLight}
                      />
                      <Box>
                        <Typography sx={{ color: text, fontWeight: 600 }}>
                          {player.username}
                        </Typography>
                        {tournament.winner_id === player._id && (
                          <Badge label="Winner" size="small">
                            <GiTrophy style={{ color: yellow, fontSize: 14 }} />
                          </Badge>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Grid>
        )}

        {(tournament.status === "IN_PROGRESS" ||
          tournament.status === "COMPLETED") && (
          <Grid item xs={12}>
            <Paper
              sx={{
                ...styles.paper,
                padding: "24px 0",
                height: "auto",
                overflow: "visible",
              }}
            >
              <Typography
                sx={{
                  ...styles.statValue,
                  marginBottom: 3,
                  paddingLeft: 3,
                }}
              >
                Tournament Bracket
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  overflowX: "auto",
                  height: "auto",
                  "&::-webkit-scrollbar": {
                    height: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: cardDark,
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: cardVeryLight,
                    borderRadius: "4px",
                  },
                }}
              >
                <TournamentBracket tournament={tournament} />
              </Box>
            </Paper>
          </Grid>
        )}

        <AddTeamsDialog
          open={showAddTeams}
          onClose={() => setShowAddTeams(false)}
          teams={newTeams}
          onAddTeam={handleAddTeam}
          onRemoveTeam={handleRemoveTeam}
          onSubmit={handleSubmitTeams}
          newTeamName={newTeamName}
          onTeamNameChange={setNewTeamName}
          isAdding={addingTeams}
          styles={styles}
        />
      </Grid>

      <LeaveConfirmationDialog
        open={showLeaveConfirmation}
        onClose={() => setShowLeaveConfirmation(false)}
        onConfirm={handleLeaveTournament}
        isLeaving={isLeaving}
      />
    </Box>
  );
};

export default TournamentDetail;
