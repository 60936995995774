import { Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";
import Avatar from "../../avatar/Avatar";
import { SET_SEARCH_USER, StoreDispatch } from "../../store/Store";
import {
  getFee,
  getGameModeString,
  getRegionString,
  getTeamSizeString,
  hexToRgbA,
  numFormatter,
} from "../../utils/helpers";
import {
  card,
  cardDark,
  cardLight,
  cardVeryLight,
  container,
  doubtedBlue,
  doubtedOrange,
  green,
  normal,
  red,
  secondaryText,
  text,
  transition,
} from "../../utils/themeContstants";
import CoinBadge from "../custom/CoinBadge";
import RivoxCoin from "../custom/RivoxCoin";
import CustomPill from "../custom/CustomPill";
import { useNavigate } from "react-router-dom";
import { CDN_URL } from "../../utils/constants";

const StreamItem = ({ stream, width, height }) => {
  const updateStore = useContext(StoreDispatch);
  const navigate = useNavigate();

  const [hovered, setHovered] = useState(false);
  const [nameHovered, setNameHovered] = useState(false);
  const [avatarHovered, setAvatarHovered] = useState(false);

  const handleOpenProfile = (user_id) => {
    updateStore({ type: SET_SEARCH_USER, payload: user_id });
  };

  const getThumbnail = () => {
    const addWidth = stream?.thumbnail_url?.replace("{width}", "440");

    return addWidth?.replace("{height}", "247");
  };

  const handleOpenStream = () => {
    window.open(`https://twitch.tv/${stream?.user_name}`, "_blank");
  };

  const styles = {
    width: {
      width: "100%",
    },
    container: {
      textOverflow: "ellipsis",
      minHeight: height ?? null,
      minWidth: width ?? null,
      flexGrow: 1,
    },
    imageContainer: {
      width: "100%",
      position: "relative",
      transition: transition,
      opacity: 1,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      overflow: "hidden",
      "&:hover": {
        cursor: "pointer",
        opacity: 0.7,
      },
    },
    title: {
      transition: transition,
      fontSize: 15,
      fontWeight: 800,
      color: hovered ? doubtedBlue : text,
      cursor: hovered ? "pointer" : "default",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 150,
    },
    meta: {
      transition: transition,
      fontSize: normal,
      fontWeight: 600,
      color: nameHovered ? text : secondaryText,
      cursor: nameHovered ? "pointer" : "default",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: 150,
    },
    viewerContainer: {
      backgroundColor: hexToRgbA(container, 0.8),
      position: "absolute",
      bottom: 10,
      left: 5,
      paddingLeft: 0.5,
      paddingRight: 0.5,
      borderRadius: 1,
    },
    viewers: {
      fontSize: 13,
      fontWeight: 600,
      color: text,
    },
    liveContainer: {
      backgroundColor: red,
      borderRadius: 1,
      paddingLeft: 0.5,
      paddingRight: 0.5,
      position: "absolute",
      left: 5,
      top: 5,
    },
    live: {
      fontSize: 11,
      fontWeight: 800,
      color: text,
    },
    userContainer: {
      position: "absolute",
      right: 5,
      top: 5,
      paddingLeft: 0.5,
      paddingRight: 0.5,
      borderRadius: 1,
      backgroundColor: hexToRgbA(doubtedBlue, 0.8),
    },
    username: {
      fontSize: 12,
      fontWeight: 800,
      color: text,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        sx={{ position: "relative" }}
      >
        <Grid item sx={{ position: "absolute", top: -12, width: "100%", zIndex: 1 }}>
          <img
            src={`${CDN_URL}christmas/ice_2.png`}
            alt="Ice Line"
            style={{ width: "100%" }}
          />
        </Grid>

        <Grid item sx={styles.imageContainer} onClick={handleOpenStream}>
          <Grid item sx={styles.liveContainer}>
            <Typography sx={styles.live}>LIVE</Typography>
          </Grid>

          <Grid item sx={styles.viewerContainer}>
            <Typography sx={styles.viewers}>
              {stream?.viewer_count}{" "}
              {`viewer${stream?.viewer_count === 1 ? "" : "s"}`}
            </Typography>
          </Grid>

          <Grid item sx={styles.userContainer}>
            <Typography sx={styles.username}>@{stream?.user_name}</Typography>
          </Grid>

          <img
            draggable={false}
            src={getThumbnail()}
            alt="twitch_stream_thumbnail"
            style={{
              objectFit: "cover",
              width: "100%",
            }}
          />
        </Grid>

        <Grid
          item
          sx={{
            width: "100%",
            overflow: "hidden",
            backgroundColor: card,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            padding: 2,
            marginTop: -0.8,
            transition: transition,
            "&:hover": {
              cursor: stream?.joining_team_name ? "pointer" : "default",
              backgroundColor: stream?.joining_team_name ? cardLight : card,
            },
          }}
          onClick={() => {
            if (stream?.joining_team_name) {
              return navigate(`/token/${stream?.match_id}`);
            }

            return;
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 1 }}
                wrap="nowrap"
              >
                {/* <Grid
              item
              onMouseEnter={() => setAvatarHovered(true)}
              onMouseLeave={() => setAvatarHovered(false)}
              onClick={() => handleOpenProfile(stream?._id)}
              sx={{
                marginLeft: -1,
                marginRight: -1,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Avatar
                size={50}
                bgColor={avatarHovered ? cardLight : card}
                avatar={stream?.avatar}
              />
            </Grid> */}

                <Grid item>
                  <Grid
                    container
                    direction="column"
                    alignItems="start"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Typography sx={styles.title}>
                        {stream?.creating_team_name}
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography sx={styles.meta}>
                        vs{" "}
                        <span style={styles.title}>
                          {stream?.joining_team_name ?? "Waiting for opponent"}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sx={{ marginLeft: "auto" }}>
                  <Grid
                    container
                    direction="column"
                    alignItems="end"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Grid
                        container
                        justifyContent="start"
                        alignItems="center"
                        gap={{ xs: 0.5 }}
                        wrap="nowrap"
                      >
                        <RivoxCoin size={25} />

                        <Grid item>
                          <Typography
                            sx={{ fontSize: 22, fontWeight: 800, color: text }}
                          >
                            {numFormatter().format(
                              parseFloat(
                                stream?.entry_fee * getFee(stream?.game) +
                                  stream?.entry_fee
                              )
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sx={{ marginTop: -0.5 }}>
                      <Typography
                        sx={{
                          fontSize: 11,
                          fontWeight: 800,
                          color: doubtedBlue,
                        }}
                      >
                        PRIZE
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={styles.width}>
              <Grid
                container
                justifyContent="start"
                alignItems="center"
                gap={{ xs: 1 }}
                wrap="nowrap"
              >
                <CustomPill
                  label={getGameModeString(stream?.game_mode) ?? "Any Mode"}
                  hoverLabel="Game mode"
                />
                <CustomPill
                  label={getTeamSizeString(stream?.team_size)}
                  hoverLabel="Team size"
                />
                <CustomPill
                  label={getRegionString(stream?.region)}
                  hoverLabel="Region"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StreamItem;
