import { Grid, Typography, Box, Divider } from "@mui/material";
import { useContext, useState } from "react";
import { cancelMatch, forceWin, resetMatch, revertTournamentMatch } from "../../api/staff";
import useMiddleware from "../../api/useMiddleware";
import {
  SET_ERRORS,
  SET_SUCCESSES,
  StoreContext,
  StoreDispatch,
} from "../../store/Store";
import { AccountTypeEnum, MatchStateEnum } from "../../utils/enums";
import {
  card,
  cardLight,
  cardVeryLight,
  large,
  modalShadow,
  text,
  red,
  cardDark,
} from "../../utils/themeContstants";
import PrimaryButton from "../custom/PrimaryButton";
import SecondaryButton from "../custom/SecondaryButton";
import UploadEvidenceModal from "./UploadEvidenceModal";
import CustomModal from "../custom/CustomModal";

const MatchStaffButtons = ({ token, user }) => {
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);
  const store = useContext(StoreContext);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [revertModalOpen, setRevertModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [loading, setLoading] = useState(null);

  const handleCancel = () => {
    setLoading("cancel");
    cancelMatch(middleware, token?._id).then((res) => {
      setLoading(null);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
      }
    });
  };

  const handleReset = () => {
    setLoading("reset");
    resetMatch(middleware, token?._id).then((res) => {
      setLoading(null);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
      }
    });
  };

  const handleRevertTeam = () => {
    if (!selectedTeam) return;
    
    setLoading('revert');
    revertTournamentMatch(middleware, token?._id, selectedTeam)
      .then((res) => {
        if (res?.error) {
          updateStore({
            type: SET_ERRORS,
            payload: res?.message,
          });
        } else {
          updateStore({
            type: SET_SUCCESSES,
            payload: "Successfully reverted team",
          });
          setRevertModalOpen(false);
        }
      })
      .finally(() => {
        setLoading(null);
        setSelectedTeam(null);
      });
  };

  const handleCloseRevertModal = () => {
    setRevertModalOpen(false);
    setSelectedTeam(null);
  };

  const getWinningTeamId = (team) => {
    switch (team) {
      case "creating_team":
        return token?.creating_team?._id;
      case "joining_team":
        return token?.joining_team?._id;
    }
  };

  const getLosingTeamId = (team) => {
    switch (team) {
      case "creating_team":
        return token?.joining_team?._id;
      case "joining_team":
        return token?.creating_team?._id;
    }
  };

  const handleForceWin = (winningTeam) => {
    if (winningTeam === "creating_team") setLoading("force_create");
    if (winningTeam === "joining_team") setLoading("force_join");
    forceWin(
      middleware,
      token?._id,
      getWinningTeamId(winningTeam),
      getLosingTeamId(winningTeam)
    ).then((res) => {
      setLoading(null);
      if (res?.error) {
        updateStore({
          type: SET_ERRORS,
          payload: res?.message,
        });
      } else {
        updateStore({
          type: SET_SUCCESSES,
          payload: res?.message,
        });
      }
    });
  };

  const styles = {
    controlsContainer: {
      width: "100%",
    },
    container: {
      padding: 2,
      borderRadius: 2,
      border: `1px solid ${cardVeryLight}`,
      width: "100%",
    },
    title: {
      fontWeight: 800,
      color: text,
      fontSize: large,
    },
    width: {
      width: "100%",
    },
    modalContent: {
      backgroundColor: card,
      borderRadius: 2,
      padding: 3,
    },
    teamContainer: {
      backgroundColor: cardDark,
      borderRadius: 1,
      padding: 2,
      marginBottom: 2,
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      position: 'relative',
      '&:hover': {
        backgroundColor: '#2a2a3a',
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      },
      '&:active': {
        transform: 'translateY(0)',
      },
    },
    selectedTeam: {
      backgroundColor: '#2a2a3a',
      boxShadow: `0 0 0 2px ${red}, 0 4px 12px rgba(255, 59, 59, 0.2)`,
      '&:hover': {
        backgroundColor: '#2a2a3a',
        boxShadow: `0 0 0 2px ${red}, 0 4px 12px rgba(255, 59, 59, 0.3)`,
      },
    },
    teamHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    teamNumber: {
      color: text,
      opacity: 0.7,
      marginRight: 1,
      fontSize: 14,
      fontWeight: 500,
    },
    teamName: {
      color: text,
      fontSize: 16,
      fontWeight: 600,
    },
    revertButton: {
      marginTop: 2,
    },
  };

  return (
    <Grid item sx={styles.container}>
      <Grid
        container
        direction="column"
        alignItems="start"
        justifyContent="center"
        gap={{ xs: 1 }}
      >
        <>
          <UploadEvidenceModal
            open={uploadOpen}
            onClose={() => setUploadOpen(false)}
            token={token}
          />
          <CustomModal
            open={revertModalOpen}
            onClose={handleCloseRevertModal}
            title="Revert Team"
          >
            <Box sx={styles.modalContent}>
              {token?.creating_team && (
                <Box 
                  sx={{
                    ...styles.teamContainer,
                    ...(selectedTeam === 'creating' && styles.selectedTeam),
                  }}
                  onClick={() => setSelectedTeam('creating')}
                >
                  <Box sx={styles.teamHeader}>
                    <Typography sx={styles.teamNumber}>Team 1:</Typography>
                    <Typography sx={styles.teamName}>
                      {token?.creating_team?.name}
                    </Typography>
                  </Box>
                </Box>
              )}

              {token?.joining_team && (
                <Box 
                  sx={{
                    ...styles.teamContainer,
                    ...(selectedTeam === 'joining' && styles.selectedTeam),
                  }}
                  onClick={() => setSelectedTeam('joining')}
                >
                  <Box sx={styles.teamHeader}>
                    <Typography sx={styles.teamNumber}>Team 2:</Typography>
                    <Typography sx={styles.teamName}>
                      {token?.joining_team?.name}
                    </Typography>
                  </Box>
                </Box>
              )}
              
              <SecondaryButton
                label={loading === 'revert' ? "Reverting..." : "Revert Team"}
                onClick={handleRevertTeam}
                disabled={!selectedTeam || loading}
                color={red}
                fullWidth
                sx={styles.revertButton}
              />
            </Box>
          </CustomModal>
        </>
        <Grid item>
          <Typography sx={styles.title}>Staff Controls</Typography>
        </Grid>

        <Grid item sx={styles.controlsContainer}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={{ xs: 1 }}
          >
            { user?.account_type >= AccountTypeEnum.SENIOR_MODERATOR &&
            <>
            {token?.state !== MatchStateEnum.CANCEL &&
              token?.state !== MatchStateEnum.COMPLETED && token?.match_type.type !== "tournament" && (
                <Grid item sx={styles.width}>
                  <SecondaryButton
                    label="Cancel Match"
                    fullWidth
                    type="secondary"
                    disabled={loading && loading !== "cancel"}
                    loading={loading === "cancel"}
                    onClick={handleCancel}
                  />
                </Grid>
              )}

            {token?.state !== MatchStateEnum.COMPLETED &&
              token?.state !== MatchStateEnum.READY &&
              token?.state !== MatchStateEnum.CANCEL &&
              token?.state !== MatchStateEnum.WAITING && (
                <>
                  <Grid item sx={styles.width}>
                    <SecondaryButton
                      label={`Force ${token?.creating_team?.name} Win`}
                      fullWidth
                      type="secondary"
                      disabled={loading && loading !== "force_create"}
                      loading={loading === "force_create"}
                      onClick={() => handleForceWin("creating_team")}
                    />
                  </Grid>

                  <Grid item sx={styles.width}>
                    <SecondaryButton
                      label={`Force ${token?.joining_team?.name} Win`}
                      fullWidth
                      type="secondary"
                      disabled={loading && loading !== "force_join"}
                      loading={loading === "force_join"}
                      onClick={() => handleForceWin("joining_team")}
                    />
                  </Grid>
                </>
              )}
            </>
          }

            {(token?.state === MatchStateEnum.DISPUTE ||
              token?.state === MatchStateEnum.COMPLETED ||
              token?.state === MatchStateEnum.SUBMIT) && (
              <Grid item sx={styles.width}>
                <SecondaryButton
                  label="Reset Match"
                  fullWidth
                  type="secondary"
                  disabled={loading && loading !== "reset"}
                  loading={loading === "reset"}
                  onClick={handleReset}
                />
              </Grid>
            )}
            {token?.state === MatchStateEnum.DISPUTE && (
              <Grid item sx={styles.width}>
                <SecondaryButton
                  label="Upload Evidence"
                  fullWidth
                  type="secondary"
                  onClick={() => setUploadOpen(true)}
                />
              </Grid>
            )}
            {token?.state === MatchStateEnum.PLAYING && (
              <>
                <Grid item sx={styles.width}>
                  <SecondaryButton
                    label="Upload Evidence"
                    fullWidth
                    type="secondary"
                    onClick={() => setUploadOpen(true)}
                  />
                </Grid>
                
              </>
            )}
            {token?.match_type?.type === "tournament" && 
            (token?.state === MatchStateEnum.WAITING
             || token?.state === MatchStateEnum.PLAYING) &&
            user?.account_type >= AccountTypeEnum.SENIOR_MODERATOR &&
             token?.round > 1 && (
              <Grid item sx={styles.width}>
                <SecondaryButton
                  label="Revert Team"
                  fullWidth
                  type="secondary"
                  color={red}
                  onClick={() => setRevertModalOpen(true)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MatchStaffButtons;
