export const SERVER_URL = process.env.REACT_APP_SERVER_URL || "https://api.rivox.gg";
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL || "https://rivox.gg";

export const API_ROUTE = `${SERVER_URL}/api`;

export const CDN_URL =`https://cdn.rivox.gg/assets/`;

export const errMessage = (err) => {
  return err?.response?.data?.message;
};
