import { Box } from "@mui/material";

const NavbarButtonLabel = ({ 
  text = "50% OFF",
  backgroundColor = "#FC1C1C",
  color = "#fff",
  innerGlowColor = "#FF8719",
  innerShadowColor = "#00000026",
  innerHighlightColor = "#FFFFFF33",
  outerGlowColor = "#FFB01966"
}) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        position: 'relative',
        zIndex: 999,
        overflow: "visible",
      }}
    >
      <Box
        sx={{
          backgroundColor,
          color,
          fontSize: "12px",
          padding: "2px 6px",
          borderRadius: "4px",
          fontWeight: "bold",
          whiteSpace: "nowrap",
          position: "relative",
          overflow: "visible",
          boxShadow: `
            0px 0px 12px 0px ${innerGlowColor} inset,
            0px -3px 0px 0px ${innerShadowColor} inset,
            0px 1px 0px 0px ${innerHighlightColor} inset,
            0px 0px 10px 0px ${outerGlowColor}
          `,
          "&:after": {
            content: '""',
            position: "absolute",
            bottom: -5,
            left: "50%",
            transform: "translateX(-50%)",
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderTop: `6px solid ${backgroundColor}`,
            zIndex: 998,
          },
        }}
      >
        {text}
      </Box>
    </Box>
  );
};

export default NavbarButtonLabel; 