import { Grid, useMediaQuery, Typography, Tooltip, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { IoStorefront } from "react-icons/io5";
import { MdLeaderboard } from "react-icons/md";
import { BsTrophy } from "react-icons/bs";
import { RiSwordFill } from "react-icons/ri";
import { AiOutlineHome } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";

import {
  container,
  doubtedBlue,
  secondaryText,
  text,
  cardVeryLight,
  transition,
} from "../../../utils/themeContstants";
import SelectGameModal from "../../custom/SelectGameModal";
import NavbarButtonLabel from "../../custom/NavbarButtonLabel";

const NavbarNavSection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width:1600px)");

  const [selected, setSelected] = useState("home");
  const [gamesOpen, setGamesOpen] = useState(false);

  useEffect(() => {
    const path = location?.pathname;
    const section = path?.split("/")[1];

    switch (section) {
      case "":
        return setSelected("home");
      case "wagers":
        return setSelected("wagers");
      case "sportsbook":
        return setSelected("sportsbook");
      case "shop":
        return setSelected("shop");
      case "tournaments":
        return setSelected("tournaments");
      case "leaderboard":
        return setSelected("leaderboard");
      default:
        return setSelected(null);
    }
  }, [location?.pathname]);

  const styles = {
    icon: {
      fontSize: 32,
      color: secondaryText,
      transform: "translateY(-2px)",
    },
    balanceContainer: {
      height: 42,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: container,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 1,
    },
    balance: {
      fontSize: 13,
      fontWeight: 700,
      color: text,
    },
    container: {
      flex: 0.33,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    navItem: {
      padding: "8px 12px",
      borderRadius: 8,
      transition: transition,
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      justifyContent: "center",
      marginTop: "10px",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: cardVeryLight,
      },
    },
    selectedNavItem: {
      backgroundColor: cardVeryLight,
    },
    navText: {
      fontSize: 15,
      fontWeight: 600,
      color: text,
      marginLeft: "6px",
      display: isDesktop ? "block" : "none",
    },
    logoIcon: {
      color: doubtedBlue,
      fontSize: 24,
    },
    labelContainer: {
      position: "absolute",
      top: "-25px",
      left: 0,
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      zIndex: 999,
      pointerEvents: "none",
      minHeight: "30px",
    }
  };

  const navItems = [
    {
      id: "home",
      label: "Home",
      icon: <AiOutlineHome style={styles.icon} />,
      onClick: () => {
        setSelected("home");
        navigate("/");
      },
    },
    {
      id: "wagers",
      label: "Matches",
      icon: <RiSwordFill style={styles.icon} />,
      onClick: () => {
        setSelected("wagers");
        navigate("/wagers/matches");
      },
      labelProps: {
        text: "50% OFF",
        backgroundColor: "#FC1C1C",
        color: "#141722",
        innerGlowColor: "#FF8719",
        innerShadowColor: "#00000026",
        innerHighlightColor: "#FFFFFF33",
        outerGlowColor: "#FFB01966"
      }
    },
    // {
    //   id: "sportsbook",
    //   label: "Sportsbook",
    //   icon: <BsReceipt style={styles.icon} />,
    //   onClick: () => {
    //     setSelected("sportsbook");
    //     navigate("/sportsbook");
    //   },
    // },
    {
      id: "tournaments",
      label: "Tournaments",
      icon: <BsTrophy style={styles.icon} />,
      onClick: () => {
        setSelected("tournaments");
        navigate("/tournaments");
      },
    },
    {
      id: "shop",
      label: "Shop",
      icon: <IoStorefront style={styles.icon} />,
      onClick: () => {
        setSelected("shop");
        navigate("/shop/avatars");
      },
    },
    {
      id: "leaderboard",
      label: "Leaderboard",
      icon: <MdLeaderboard style={styles.icon} />,
      onClick: () => {
        setSelected("leaderboard");
        navigate("/leaderboard");
      },
    },
  ];

  return (
    <>
      <SelectGameModal
        title="Browse Games"
        onClose={(selectedGame) => {
          setGamesOpen(false);
          if (selectedGame) {
            navigate(`/${selectedGame}/matches`);
          }
        }}
      />

      <Grid item sx={styles.container}>
        <Grid
          container
          alignItems="center"
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: isDesktop ? "800px" : "100%",
            margin: "0 auto",
            gap: isDesktop ? "35px" : "0px",
            overflowY: "visible",
            paddingTop: "5px",
            transform: "translateY(-10px)",
            "&::-webkit-scrollbar": { display: "none" },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {navItems.map((item) => (
            <Tooltip
              key={item.id}
              title={!isDesktop ? item.label : ""}
              placement="bottom"
            >
              <Grid
                item
                sx={{
                  position: "relative",
                  ...styles.navItem,
                  ...(selected === item.id && styles.selectedNavItem),
                  flex: 1,
                  textAlign: "center",
                  minWidth: isDesktop ? "auto" : "64px",
                }}
                onClick={item.onClick}
              >
                {item.labelProps && (
                  <Box sx={styles.labelContainer}>
                    <NavbarButtonLabel {...item.labelProps} />
                  </Box>
                )}
                {item.icon}
                <Typography sx={styles.navText}>{item.label}</Typography>
              </Grid>
            </Tooltip>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default NavbarNavSection;
