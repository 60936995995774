import { Grid } from "@mui/material";
import MatchTeamItem from "./MatchTeamItem";
import MatchWaitingTeamItem from "./MatchWaitingTeamItem";

const MatchWaitingState = ({ token }) => {
  const styles = {
    width: {
      width: "100%",
    },
  };
  console.log(token?.creating_team);
  return (
    <Grid item sx={styles.width}>
      <Grid
        container
        alignItems="stretch"
        justifyContent="center"
        gap={{ xs: 2 }}
      >
        {token?.creating_team_obj ? (
          <MatchTeamItem
            isCreatingTeam={true}
            team={token?.creating_team}
            token={token}
          />
        ) : (
          <MatchWaitingTeamItem token={token} />
        )}

        {token?.joining_team_obj ? (
          <MatchTeamItem
            isCreatingTeam={false}
            team={token?.joining_team}
            token={token}
          />
        ) : (
          <MatchWaitingTeamItem token={token} />
        )}
      </Grid>
    </Grid>
  );
};

export default MatchWaitingState;
