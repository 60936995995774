import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Typography,
  useMediaQuery,
  Paper,
  Skeleton,
  Box,
} from "@mui/material";
import { getTournaments } from "../../api/tournaments";
import useMiddleware from "../../api/useMiddleware";
import { StoreContext, StoreDispatch, SET_ERRORS } from "../../store/Store";
import {
  text,
  cardDark,
  cardVeryLight,
  secondaryText,
  menuShadow,
  card,
  doubtedBlue,
} from "../../utils/themeContstants";
import { GiTrophy } from "react-icons/gi";
import TournamentCard from "./TournamentCard";
import PrimaryButton from "../custom/PrimaryButton";
import CreateTournament from "./CreateTournament";
import Empty from "../custom/Empty";
import { AccountTypeEnum } from "../../utils/enums";

const Tournaments = () => {
  const [tournaments, setTournaments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [createOpen, setCreateOpen] = useState(false);
  const middleware = useMiddleware();
  const store = useContext(StoreContext);
  const updateStore = useContext(StoreDispatch);
  const isTablet = useMediaQuery("(max-width:1024px)");

  useEffect(() => {
    if (loading) {
      getTournaments(middleware).then((res) => {
        setLoading(false);
        if (res?.error) {
          updateStore({ type: SET_ERRORS, payload: res?.message });
        } else {
          setTournaments(res);
        }
      });
    }
  }, [loading]);

  const styles = {
    container: {
      width: "100%",
      minHeight: "100vh",
      position: "relative",
      padding: isTablet ? "12px" : "24px",
      backgroundColor: "rgb(26, 44, 57)",
      display: "flex",
      justifyContent: "center"
    },
    contentWrapper: {
      width: "100%",
      maxWidth: "1400px",
      margin: "0 auto"
    },
    header: {
      fontSize: isTablet ? 28 : 36,
      fontWeight: 800,
      color: text,
      textTransform: "uppercase",
      letterSpacing: 0.5,
    },
    subHeader: {
      fontSize: 16,
      color: secondaryText,
      fontWeight: 500,
    },
    paper: {
      backgroundColor: card,
      padding: isTablet ? "16px" : "24px",
      boxShadow: menuShadow,
      borderRadius: 2,
      border: `1px solid ${cardVeryLight}`,
    },
    sectionTitle: {
      fontSize: 20,
      fontWeight: 700,
      color: text,
      marginBottom: 2,
    },
  };

  if (loading) {
    return (
      <Grid container sx={styles.container} spacing={3}>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography sx={styles.header}>Tournaments</Typography>
            </Grid>
            <Grid item>
              <Typography sx={styles.subHeader}>
                Compete in tournaments to win prizes
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {[1, 2, 3].map((i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <Skeleton
                  variant="rectangular"
                  height={200}
                  sx={{ bgcolor: cardVeryLight, borderRadius: 2 }}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const activeTournaments =
    tournaments?.filter((t) => t.status === "IN_PROGRESS") || [];
  const upcomingTournaments =
    tournaments?.filter((t) => t.status === "PENDING" || t.status === "REGISTRATION") || [];

  return (
    <>
      <CreateTournament
        open={createOpen}
        onClose={() => setCreateOpen(false)}
      />

      <Box sx={styles.container}>
        <Box sx={styles.contentWrapper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Typography sx={styles.header}>Tournaments</Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={styles.subHeader}>
                        Compete in tournaments to win prizes
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {store?.user?.account_type >= AccountTypeEnum.GOD && (
                  <Grid item>
                    <PrimaryButton
                      label="Create Tournament"
                      onClick={() => setCreateOpen(true)}
                      color={doubtedBlue}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            {(!tournaments || tournaments.length === 0) && (
              <Grid item xs={12}>
                <Empty
                  title="No tournaments"
                  label="Check back later for upcoming tournaments"
                  icon={<GiTrophy style={{ fontSize: 48, color: text }} />}
                />
              </Grid>
            )}

            {upcomingTournaments.length > 0 && (
              <Grid item xs={12}>
                <Paper sx={styles.paper}>
                  <Typography sx={styles.sectionTitle}>
                    Upcoming Tournaments
                  </Typography>
                  <Grid 
                    container 
                    spacing={isTablet ? 1 : 2}
                    sx={{
                      justifyContent: "center",
                      margin: 0,
                      width: "100%"
                    }}
                  >
                    {upcomingTournaments.map((tournament) => (
                      <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        md={5} 
                        key={tournament._id}
                        sx={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <TournamentCard tournament={tournament} />
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
            )}

            {activeTournaments.length > 0 && (
              <Grid item xs={12}>
                <Paper sx={styles.paper}>
                  <Typography sx={styles.sectionTitle}>
                    Active Tournaments
                  </Typography>
                  <Grid 
                    container 
                    spacing={isTablet ? 1 : 2}
                    sx={{
                      justifyContent: "center",
                      margin: 0,
                      width: "100%"
                    }}
                  >
                    {activeTournaments.map((tournament) => (
                      <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        md={3.5} 
                        key={tournament._id}
                        sx={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        <TournamentCard tournament={tournament} />
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Tournaments;
