import React, { useState } from 'react';
import { 
  Dialog,
  DialogContent,
  DialogTitle,
  Grid, 
  Typography,
  IconButton,
  Divider,
  Box,
  Button
} from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { createTournament } from '../../api/tournaments';
import useMiddleware from '../../api/useMiddleware';
import { StoreDispatch, SET_ERRORS, SET_SUCCESSES } from '../../store/Store';
import { useContext } from 'react';
import { 
  text, 
  cardDark, 
  cardVeryLight, 
  secondaryText,
  doubtedBlue,
  red
} from '../../utils/themeContstants';
import CustomDropdown from '../custom/CustomDropdown';
import CustomInput from '../custom/CustomInput';
import PrimaryButton from '../custom/PrimaryButton';
import { 
  newGameModeOptions, 
  regionOptions, 
  platformOptions 
} from '../../utils/options';
import CustomCheckbox from '../custom/CustomCheckbox';


const GAME_OPTIONS = [
  { title: 'Fortnite', value: 'fortnite' },
  { title: 'Warzone', value: 'warzone' },
  { title: 'Chess', value: 'chess' },
  { title: 'FIFA', value: 'fifa' },
  { title: 'Clash Royale', value: 'clashroyale' }
];

const MAX_PLAYERS_OPTIONS = [
  { title: '2 Teams', value: '2' },
  { title: '4 Teams', value: '4' },
  { title: '8 Teams', value: '8' },
  { title: '16 Teams', value: '16' },
  { title: '32 Teams', value: '32' },
  { title: '64 Teams', value: '64' },
  { title: '128 Teams', value: '128' },
  { title: '256 Teams', value: '256' }
];

const TEAM_SIZE_OPTIONS = [
  { title: '1v1', value: 1 },
  { title: '2v2', value: 2 },
  { title: '3v3', value: 3 },
  { title: '4v4', value: 4 }
];

const CreateTournament = ({ open, onClose, user }) => {
  const [formData, setFormData] = useState({
    name: '',
    game: '',
    game_match_type: '',
    entry_fee: 0,
    max_players: 2,
    team_size: 1,
    first_to: 1,
    region: '',
    platform: null,
    rules: '',
    prize_structure: [],
    start_time: '',
    registration_opens_at: null,
    is_locked: false
  });
  const middleware = useMiddleware();
  const updateStore = useContext(StoreDispatch);

  // Sample data for quick testing
  const fillSampleData = () => {
    const futureDate = new Date();
    futureDate.setHours(futureDate.getHours() + 1); // Set to 1 hour from now
    
    setFormData({
      name: `1v1 Realistic`,
      game: 'fortnite',
      game_match_type: 'real',
      region: 'eu',
      max_players: 128,
      entry_fee: 0,
      platform: null,
      team_size: 1,
      first_to: 3,
      start_time: futureDate.toISOString().slice(0, 16), // Format: "YYYY-MM-DDThh:mm"
      prize_structure: [
        { place: 1, amount: 12 },
        { place: 2, amount: 6 },
        { place: 3, amount: 4 },
        { place: 4, amount: 2 },
        { place: 5, amount: 1 },
        { place: 6, amount: 0.5 },
      ],
      is_locked: false
    });
  };

  const handleAddPrizePlace = () => {
    setFormData(prev => ({ ...prev, prize_structure: [...prev.prize_structure, { place: prev.prize_structure.length + 1, amount: '' }] }));
  };

  const handlePrizeChange = (index, amount) => {
    const newPrizes = [...formData.prize_structure];
    newPrizes[index].amount = amount;
    setFormData(prev => ({ ...prev, prize_structure: newPrizes }));
  };

  const handleRemovePrize = (indexToRemove) => {
    setFormData(prev => ({ ...prev, prize_structure: prev.prize_structure.filter((_, index) => index !== indexToRemove) }));
  };

  const handleCreate = () => {
    console.log(formData);
    if (!formData.name || !formData.game || !formData.game_match_type || !formData.region || !formData.max_players || !formData.first_to || !formData.start_time) {
      updateStore({ type: SET_ERRORS, payload: 'Please fill in all required fields' });
      return;
    }

    const startTime = new Date(formData.start_time);
    if (isNaN(startTime.getTime())) {
      updateStore({ type: SET_ERRORS, payload: 'Please enter a valid start time' });
      return;
    }

    if (startTime < new Date()) {
      updateStore({ type: SET_ERRORS, payload: 'Start time must be in the future' });
      return;
    }

    const tournamentData = {
      name: formData.name,
      game: formData.game,
      game_match_type: formData.game_match_type,
      region: formData.region,
      max_players: parseInt(formData.max_players),
      team_size: parseInt(formData.team_size),
      entry_fee: parseFloat(formData.entry_fee) || 0,
      first_to: parseInt(formData.first_to),
      platform: formData.platform,
      start_time: formData.start_time,
      registration_opens_at: formData.registration_opens_at,
      is_locked: formData.is_locked,
      prize_structure: formData.prize_structure.filter(prize => prize.amount).map(prize => ({
        place: prize.place,
        amount: parseFloat(prize.amount)
      }))
    };

    createTournament(middleware, tournamentData).then((res) => {
      if (res?.error) {
        updateStore({ type: SET_ERRORS, payload: res?.message });
      } else {
        updateStore({ type: SET_SUCCESSES, payload: 'Tournament created successfully' });
        handleClose();
      }
    });
  };

  const handleClose = () => {
    setFormData({
      name: '',
      game: '',
      game_match_type: '',
      entry_fee: 0,
      max_players: 2,
      team_size: 1,
      first_to: 1,
      region: '',
      platform: null,
      rules: '',
      prize_structure: [],
      start_time: '',
      registration_opens_at: null,
      is_locked: false
    });
    onClose();
  };

  const styles = {
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: cardDark,
        borderRadius: 2,
        maxWidth: 600,
        width: '100%',
        margin: '16px',
        boxSizing: 'border-box'
      }
    },
    title: {
      padding: '20px 24px',
      color: text,
      fontSize: 24,
      fontWeight: 700
    },
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: secondaryText
    },
    content: {
      padding: '0 16px 16px',
    },
    divider: {
      backgroundColor: cardVeryLight,
      margin: '12px 0'
    },
    label: {
      fontSize: 14,
      fontWeight: 600,
      color: secondaryText,
      marginBottom: 1
    },
    width: {
      width: '100%'
    },
    prizeBox: {
      backgroundColor: cardVeryLight,
      borderRadius: 1,
      padding: '12px 6px',
      marginBottom: 2,
      width: '100%',
      boxSizing: 'border-box'
    },
    prizeHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 2
    },
    prizeTitle: {
      fontSize: 16,
      fontWeight: 600,
      color: text
    },
    prizeItem: {
      display: 'flex',
      alignItems: 'center',
      gap: "2px",
      marginBottom: 1,
      backgroundColor: cardDark,
      padding: '4px',
      borderRadius: 1,
      width: '100%',
      boxSizing: 'border-box'
    },
    placeLabel: {
      fontSize: 14,
      fontWeight: 600,
      color: text,
      width: '35px',
      flexShrink: 0
    },
    prizeInput: {
      flex: 1,
      minWidth: 0,
      maxWidth: 'calc(100% - 65px)'
    },
    removeButton: {
      color: red,
      padding: 0,
      width: '20px',
      height: '20px',
      minWidth: '20px',
      flexShrink: 0,
      marginLeft: '2px',
      '& svg': {
        fontSize: '14px'
      },
      '&:hover': {
        color: `${red}dd`,
        backgroundColor: 'transparent'
      }
    },
    addPrizeButton: {
      marginTop: 1,
      borderColor: cardVeryLight,
      color: text,
      '&:hover': {
        borderColor: text,
        backgroundColor: 'transparent'
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={styles.dialog}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={styles.title}>
        Create Tournament
        <Box sx={{ position: 'absolute', right: 48, top: 8 }}>
          <Button
            onClick={fillSampleData}
            sx={{
              color: secondaryText,
              '&:hover': {
                color: text
              }
            }}
          >
            Fill Sample Data
          </Button>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={styles.closeButton}
        >
          <IoClose />
        </IconButton>
      </DialogTitle>

      <Divider sx={styles.divider} />

      <DialogContent sx={styles.content}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography sx={styles.label}>Tournament Name*</Typography>
            <CustomInput
              placeholder="Enter tournament name"
              value={formData.name}
              onChange={(value) => setFormData({ ...formData, name: value })}
              fullWidth
            />
          </Grid>

          <Grid item>
            <Typography sx={styles.label}>Game*</Typography>
            <CustomDropdown
              options={GAME_OPTIONS}
              placeholder="Select game"
              value={formData.game}
              onChange={(value) => {
                setFormData({ ...formData, game: value, game_match_type: '' });
              }}
              backgroundColor={cardDark}
              borderColor={cardVeryLight}
            />
          </Grid>

          {formData.game && (
            <Grid item>
              <Typography sx={styles.label}>Game Mode*</Typography>
              <CustomDropdown
                options={newGameModeOptions(formData.game)}
                placeholder="Select game mode"
                value={formData.game_match_type}
                onChange={(value) => {
                  setFormData({ ...formData, game_match_type: value });
                }}
                backgroundColor={cardDark}
                borderColor={cardVeryLight}
              />
            </Grid>
          )}

          <Grid item>
            <Typography sx={styles.label}>Region*</Typography>
            <CustomDropdown
              options={regionOptions()}
              placeholder="Select region"
              value={formData.region}
              onChange={(value) => {
                setFormData({ ...formData, region: value });
              }}
              backgroundColor={cardDark}
              borderColor={cardVeryLight}
            />
          </Grid>

          <Grid item>
            <Typography sx={styles.label}>Platform*</Typography>
            <CustomDropdown
              options={platformOptions}
              placeholder="Select platform"
              value={formData.platform}
              onChange={(value) => {
                setFormData({ ...formData, platform: value });
              }}
              backgroundColor={cardDark}
              borderColor={cardVeryLight}
            />
          </Grid>

          <Grid item>
            <Typography sx={styles.label}>Maximum Players*</Typography>
            <CustomDropdown
              options={MAX_PLAYERS_OPTIONS}
              placeholder="Select max players"
              value={formData.max_players?.toString()}
              onChange={(value) => {
                setFormData({ ...formData, max_players: value });
              }}
              backgroundColor={cardDark}
              borderColor={cardVeryLight}
            />
          </Grid>

          <Grid item>
            <Typography sx={styles.label}>Team Size*</Typography>
            <CustomDropdown
              options={TEAM_SIZE_OPTIONS}
              placeholder="Select team size"
              value={formData.team_size}
              onChange={(value) => {
                setFormData({ ...formData, team_size: value });
              }}
              backgroundColor={cardDark}
              borderColor={cardVeryLight}
            />
          </Grid>

          {/* <Grid item>
            <Typography sx={styles.label}>Entry Fee</Typography>
            <CustomInput
              type="number"
              placeholder="Enter entry fee (optional)"
              value={formData.entry_fee}
              onChange={(value) => setFormData({ ...formData, entry_fee: value })}
              fullWidth
            />
          </Grid> */}

          <Grid item>
            <Typography sx={styles.label}>First To*</Typography>
            <CustomInput
              type="number"
              min="1"
              placeholder="Enter first to value"
              value={formData.first_to}
              onChange={(value) => setFormData({ ...formData, first_to: parseInt(value) || 1 })}
              fullWidth
            />
          </Grid>

          <Grid item>
            <Typography sx={styles.label}>Start Time*</Typography>
            <CustomInput
              type="datetime-local"
              placeholder="Select start time"
              value={formData.start_time}
              onChange={(value) => setFormData({ ...formData, start_time: value })}
              fullWidth
            />
          </Grid>

          <Grid item>
            <Typography sx={styles.label}>Registration Opens At</Typography>
            <CustomInput
              type="datetime-local"
              placeholder="Select registration time"
              value={formData.registration_opens_at}
              onChange={(value) => setFormData({ 
                ...formData, 
                registration_opens_at: value,
                is_locked: false // Reset is_locked when registration time is set
              })}
              fullWidth
              disabled={formData.is_locked}
            />
          </Grid>

          <Grid item>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <CustomCheckbox
                checked={formData.is_locked}
                onChange={(e) => setFormData({ 
                  ...formData, 
                  is_locked: e.target.checked,
                  registration_opens_at: e.target.checked ? null : formData.registration_opens_at // Reset registration time when locked
                })}
                disabled={!!formData.registration_opens_at}
              />
              <Typography sx={styles.label}>Qualifiers Only</Typography>
            </Box>
          </Grid>

          <Grid item>
            <Box sx={styles.prizeBox}>
              <Box sx={styles.prizeHeader}>
                <Typography sx={styles.prizeTitle}>Prize Structure</Typography>
                <PrimaryButton
                  label="Add Prize Place"
                  onClick={handleAddPrizePlace}
                  variant="outlined"
                  size="small"
                  sx={styles.addPrizeButton}
                />
              </Box>
              {formData.prize_structure.map((prize, index) => (
                <Box key={index} sx={styles.prizeItem}>
                  <Typography sx={styles.placeLabel}>
                    {prize.place}{prize.place === 1 ? 'st' : prize.place === 2 ? 'nd' : prize.place === 3 ? 'rd' : 'th'}
                  </Typography>
                  <Box sx={styles.prizeInput}>
                    <CustomInput
                      type="number"
                      placeholder="Enter prize amount"
                      value={prize.amount}
                      onChange={(value) => handlePrizeChange(index, value)}
                      fullWidth
                      startAdornment="$"
                    />
                  </Box>
                  {formData.prize_structure.length > 1 && (
                    <IconButton 
                      onClick={() => handleRemovePrize(index)}
                      sx={styles.removeButton}
                      size="small"
                    >
                      <IoClose />
                    </IconButton>
                  )}
                </Box>
              ))}
            </Box>
          </Grid>

          <Grid item>
            <PrimaryButton
              label="Create Tournament"
              onClick={handleCreate}
              fullWidth
              color={doubtedBlue}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTournament;
