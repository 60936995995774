import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Paper,
  Box,
  useMediaQuery,
  Divider,
} from "@mui/material";
import {
  text,
  cardVeryLight,
  secondaryText,
  offWhite,
  doubtedBlue,
  green,
  yellow,
  red,
  transition,
} from "../../utils/themeContstants";
import { GiPodium } from "react-icons/gi";
import {
  FaUserFriends,
  FaGamepad,
  FaMapMarkerAlt,
  FaRegClock,
} from "react-icons/fa";
import { BiMoney } from "react-icons/bi";
import { format, formatDistanceToNow, isPast } from "date-fns";
import Avatar from "../../avatar/Avatar";
import Badge from "../custom/Badge";
import { gameModeOptions } from "../../utils/options";
import RivoxCoin from "../custom/RivoxCoin";
import { getMatchTypeImage } from "../../utils/helpers";
import zIndex from "@mui/material/styles/zIndex";
import { CDN_URL } from "../../utils/constants";

const TournamentCountdown = ({ startTime }) => {
  const [timeLeft, setTimeLeft] = useState("");
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const start = new Date(startTime);
      const diffInSeconds = Math.floor((start - now) / 1000);

      if (diffInSeconds <= 0) {
        setHasStarted(true);
        return;
      }

      if (diffInSeconds < 60) {
        // Less than a minute, show seconds
        setTimeLeft(`Starts in ${diffInSeconds}s`);
      } else if (diffInSeconds < 3600) {
        // Less than an hour, show minutes
        const minutes = Math.floor(diffInSeconds / 60);
        setTimeLeft(`Starts in ${minutes}m`);
      } else if (diffInSeconds < 86400) {
        // Less than a day, show hours
        const hours = Math.floor(diffInSeconds / 3600);
        setTimeLeft(`Starts in ${hours}h`);
      } else {
        // More than a day, show days
        const days = Math.floor(diffInSeconds / 86400);
        setTimeLeft(`Starts in ${days}d`);
      }
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [startTime]);

  if (hasStarted) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "6px",
        backgroundColor: "rgba(26, 44, 57, 0.95)",
        padding: "4px 10px",
        borderRadius: "8px",
        border: `1px solid ${doubtedBlue}40`,
        color: doubtedBlue,
        fontSize: 12,
        fontWeight: 600,
        width: "fit-content",
      }}
    >
      <FaRegClock size={12} />
      {timeLeft}
    </Box>
  );
};

const RegistrationCountdown = ({ registrationTime }) => {
  const [timeLeft, setTimeLeft] = useState("");
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    const updateCountdown = () => {
      const now = new Date();
      const registration = new Date(registrationTime);
      const diffInSeconds = Math.floor((registration - now) / 1000);

      if (diffInSeconds <= 0) {
        setHasStarted(true);
        return;
      }

      if (diffInSeconds < 60) {
        setTimeLeft(`Opens in ${diffInSeconds}s`);
      } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        setTimeLeft(`Opens in ${minutes}m`);
      } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        setTimeLeft(`Opens in ${hours}h`);
      } else {
        const days = Math.floor(diffInSeconds / 86400);
        setTimeLeft(`Opens in ${days}d`);
      }
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, [registrationTime]);

  if (hasStarted) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "6px",
        backgroundColor: "rgba(26, 44, 57, 0.95)",
        padding: "4px 10px",
        borderRadius: "8px",
        border: `1px solid ${doubtedBlue}40`,
        color: doubtedBlue,
        fontSize: 12,
        fontWeight: 600,
        width: "fit-content",
      }}
    >
      <FaRegClock size={12} />
      {timeLeft}
    </Box>
  );
};

const TournamentCard = ({ tournament }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const isTablet = useMediaQuery("(max-width:768px)");

  const rivoxLogo = `${CDN_URL}rivox-logo.svg`;
  const faxutyLogo = `${CDN_URL}faxuty.png`;

  const getStatusColor = (status) => {
    switch (status) {
      case "REGISTRATION":
      case "PENDING":
        return yellow;
      case "IN_PROGRESS":
        return green;
      case "COMPLETED":
        return red;
      default:
        return offWhite;
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "PENDING":
      case "REGISTRATION":
        if (tournament.registration_opens_at) {
          return `Registration Opens ${format(new Date(tournament.registration_opens_at), "MMM d 'at' h:mma")}`;
        }
        if (tournament.is_locked) {
          return "Qualifiers Only";
        }
        return "Registration Open";
      case "IN_PROGRESS":
        return "Tournament Live";
      case "COMPLETED":
        return "Tournament Ended";
      default:
        return status;
    }
  };

  const styles = {
    container: {
      width: "100%",
      maxWidth: "720px",
      backgroundColor: "rgba(255, 255, 255, 0.03)",
      borderRadius: 2,
      padding: isTablet ? 1 : 1.5,
      cursor: "pointer",
      transition: transition,
      transform: isHovered ? "translateY(-4px)" : "none",
      boxShadow: isHovered
        ? `0 8px 24px rgba(0,0,0,0.3)`
        : "0 4px 12px rgba(0,0,0,0.1)",
      position: "relative",
      overflow: "hidden",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: "4px",
        background: tournament.name.toLowerCase().includes('finals') ? red : 
                   tournament.name.toLowerCase().includes('opens') ? '#3498db' : 
                   doubtedBlue,
        transition: "opacity 0.3s ease",
        opacity: isHovered ? 1 : 0.7,
      },
    },
    card: {
      backgroundColor: "rgb(26, 44, 57)",
      padding: isTablet ? "16px" : "20px",
      borderRadius: 1,
      height: isTablet ? "auto" : "480px",
      minHeight: isTablet ? "440px" : "480px",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      zIndex: 1,
    },
    title: {
      fontSize: isTablet ? 19 : 21,
      fontWeight: 800,
      color: "#FFFFFF",
      marginBottom: 1,
      zIndex: 2,
      transition: "color 0.3s ease",
    },
    meta: {
      fontSize: 13,
      fontWeight: 500,
      color: secondaryText,
    },
    prizePool: {
      fontSize: isTablet ? 22 : 26,
      fontWeight: "bold",
      color: yellow,
      display: "flex",
      alignItems: "center",
      gap: 1,
      justifyContent: "left",
      marginTop: isTablet ? 1 : 0,
      zIndex: 2,
    },
    infoGrid: {
      display: "grid",
      gridTemplateColumns: isTablet ? "1fr" : "repeat(2, 1fr)",
      gap: 1.5,
      marginTop: 2,
      marginBottom: 2,
    },
    infoContainer: {
      display: "flex",
      alignItems: "center",
      gap: 1,
      color: secondaryText,
      fontSize: isTablet ? 13 : 14,
      padding: "10px 12px",
      borderRadius: 1,
      backgroundColor: "rgba(255,255,255,0.02)",
      transition: "all 0.3s ease",
      "&:hover": {
        backgroundColor: "rgba(255,255,255,0.04)",
        transform: "translateY(-2px)",
      },
    },
    infoText: {
      color: text,
      fontWeight: 600,
      fontSize: isTablet ? 13 : 14,
    },
    avatarWrapper: {
      width: isTablet ? 30 : 34,
      height: isTablet ? 30 : 34,
      borderRadius: "50%",
      overflow: "hidden",
      backgroundColor: cardVeryLight,
      border: `2px solid ${doubtedBlue}40`,
      transition: "transform 0.3s ease",
      marginLeft: "-8px",
      "&:first-of-type": {
        marginLeft: 0,
      },
      "&:hover": {
        transform: "scale(1.1)",
        zIndex: 2,
      },
    },
    playerCount: {
      fontSize: isTablet ? 12 : 13,
      fontWeight: 600,
      color: text,
      marginLeft: "4px",
      backgroundColor: "rgba(255,255,255,0.05)",
      padding: "4px 8px",
      borderRadius: "12px",
      border: `1px solid ${doubtedBlue}40`,
    },
    statusIndicator: {
      display: "flex",
      alignItems: "center",
      gap: "6px",
      color: getStatusColor(tournament.status),
      fontSize: 13,
      fontWeight: 600,
    },
    avatarContainer: {
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap",
      gap: 1,
      marginTop: "auto",
      paddingTop: 2,
    },
  };

  return (
    <Grid
      item
      sx={styles.container}
      onClick={() => navigate(`/tournaments/${tournament._id}`)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Paper sx={styles.card}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: 0,
            minHeight: isTablet ? "80px" : "100px",
            position: "relative",
            zIndex: 2,
          }}
        >
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <Typography sx={styles.title}>
                {tournament.name}
              </Typography>
              {tournament.name.toLowerCase().includes('faxuty') && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 2 }}>
                  <img src={rivoxLogo} style={{ width: '64px', height: '64px', objectFit: 'contain' }} alt="Rivox" />
                  <Typography sx={{ color: secondaryText, fontSize: '16px', fontWeight: 600 }}>X</Typography>
                  <img src={faxutyLogo} style={{ width: '72px', height: '72px', objectFit: 'contain' }} alt="Faxuty" />
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
              <Box sx={styles.statusIndicator}>
                {tournament.status === "IN_PROGRESS" ? (
                  <div className="live-green" />
                ) : (
                  <GiPodium size={isTablet ? 13 : 15} />
                )}
                {getStatusLabel(tournament.status)}
              </Box>
            </Box>
          </Box>
        </Box>

        <div
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(26, 44, 57, 0) 0%, rgba(26, 44, 57, 0.2) 50%, rgb(26, 44, 57) 100%), url(${getMatchTypeImage(
              tournament?.game_match_type,
              tournament?.game,
              tournament?.game_mode_options
            )})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            width: "100%",
            borderRadius: "0px 0px 8px 8px",
            top: 0,
            left: 0,
            right: 0,
            height: isTablet ? "150px" : "167px",
            zIndex: 0,
            opacity: 0.3,
            pointerEvents: "none",
          }}
        />

        <Box sx={styles.prizePool}>
          <RivoxCoin size={isTablet ? 26 : 30} />
          {tournament.prize_pool.toFixed(2)}
        </Box>

        <Box
          sx={{
            mt: 1,
            minHeight: "30px",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          {tournament.registration_opens_at && tournament.status === "PENDING" && !tournament.is_locked && (
            <RegistrationCountdown registrationTime={tournament.registration_opens_at} />
          )}
          {tournament.start_time && tournament.status === "PENDING" && (
            <TournamentCountdown startTime={tournament.start_time} />
          )}
        </Box>

        <Box sx={styles.infoGrid}>
          <Box sx={styles.infoContainer}>
            <FaUserFriends size={17} />
            <Box>
              <Typography sx={styles.infoText}>
                {tournament.registered_teams.length}/{tournament.max_players}
              </Typography>
              <Typography sx={styles.meta}>Teams</Typography>
            </Box>
          </Box>

          <Box sx={styles.infoContainer}>
            <FaGamepad size={17} />
            <Box>
              <Typography sx={styles.infoText}>
                {
                  gameModeOptions.find(
                    (option) => option.value === tournament.game_match_type
                  )?.title
                }
              </Typography>
              <Typography sx={styles.meta}>Mode</Typography>
            </Box>
          </Box>

          <Box sx={styles.infoContainer}>
            <FaMapMarkerAlt size={17} />
            <Box>
              <Typography sx={styles.infoText}>
                {tournament.region.toUpperCase()}
              </Typography>
              <Typography sx={styles.meta}>Region</Typography>
            </Box>
          </Box>

          <Box sx={styles.infoContainer}>
            <FaUserFriends size={17} />
            <Box>
              <Typography sx={styles.infoText}>
                {tournament.team_size}v{tournament.team_size}
              </Typography>
              <Typography sx={styles.meta}>Team Size</Typography>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ my: 2, borderColor: `${doubtedBlue}20` }} />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            gap: 0,
            pl: 1,
          }}
        >
          {tournament.registered_players
            .slice(0, isTablet ? 4 : 6)
            .map((player, index) => (
              <Box
                key={index}
                sx={{
                  ...styles.avatarWrapper,
                  width: isTablet ? 30 : 34,
                  height: isTablet ? 30 : 34,
                  zIndex: 20 - index,
                }}
              >
                <Avatar
                  size={isTablet ? 30 : 34}
                  avatar={player.new_avatar}
                  bgColor={cardVeryLight}
                />
              </Box>
            ))}
          {tournament.registered_players.length > (isTablet ? 4 : 6) && (
            <Typography
              sx={{
                ...styles.playerCount,
                fontSize: isTablet ? 12 : 13,
                padding: "4px 8px",
                marginLeft: "4px",
              }}
            >
              +{tournament.registered_players.length - (isTablet ? 4 : 6)}
            </Typography>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};

export default TournamentCard;
