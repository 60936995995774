import { API_ROUTE, errMessage } from "../utils/constants";

export const getTournaments = async (useMiddleware, filters = {}) => {
  const params = new URLSearchParams(filters);
  return await useMiddleware
    .get(`${API_ROUTE}/tournaments?${params}`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const getTournament = async (useMiddleware, tournamentId) => {
  return await useMiddleware
    .get(`${API_ROUTE}/tournaments/${tournamentId}`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const createTournament = async (useMiddleware, tournamentData) => {
  return await useMiddleware
    .post(`${API_ROUTE}/tournaments/create`, tournamentData)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const registerForTournament = async (useMiddleware, tournament_id, team_id) => {
  return await useMiddleware
    .post(`${API_ROUTE}/tournaments/${tournament_id}/register`, { team_id })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const addTeamsToTournament = async (useMiddleware, tournamentId, teams) => {
  return await useMiddleware
    .post(`${API_ROUTE}/tournaments/${tournamentId}/teams`, { teams })
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const startTournament = async (useMiddleware, tournamentId) => {
  return await useMiddleware
    .post(`${API_ROUTE}/tournaments/${tournamentId}/start`)
    .then((res) => res.data)
    .catch((err) => {
      return { error: true, message: errMessage(err) };
    });
};

export const leaveTournament = async (middleware, tournamentId) => {
  try {
    const response = await middleware.post(`${API_ROUTE}/tournaments/${tournamentId}/leave`);
    return response.data;
  } catch (error) {
    return {
      error: true,
      message: error.response?.data?.message || "Failed to leave tournament",
    };
  }
}; 