import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import {
  text,
  offWhite,
  doubtedBlue,
  cardVeryLight,
  cardDark,
  yellow,
} from "../../utils/themeContstants";
import Avatar from "../../avatar/Avatar";
import { useNavigate } from "react-router-dom";
import { GiTrophy } from "react-icons/gi";
import { FaLongArrowAltRight } from "react-icons/fa";

// Constants used throughout for spacing
const MATCH_HEIGHT = 80;
const DESKTOP_GAP = 50;
const MOBILE_GAP = 80;
const ROUND_GAP = 100;

const MatchBox = ({ match, roundIndex, matchIndex, maxRounds, tournament }) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const MATCH_GAP = isMobile ? MOBILE_GAP : DESKTOP_GAP;
  const navigate = useNavigate();

  // Recursive function to compute the vertical position of (roundIndex, matchIndex)
  // in a single‐elimination bracket. For roundIndex = 0 (the first round),
  // simply stack matches one after another. For higher rounds,
  // place each match at the midpoint of its two parents.
  function getMarginTop(rIndex, mIndex) {
    if (rIndex === 0) {
      // First‐round matches: evenly spaced
      return mIndex * (MATCH_HEIGHT + MATCH_GAP);
    } else {
      // Recursively find the margin of its two parent matches, then take their average
      const parent0 = getMarginTop(rIndex - 1, mIndex * 2);
      const parent1 = getMarginTop(rIndex - 1, mIndex * 2 + 1);
      return (parent0 + parent1) / 2 - 8;
    }
  }

  // Calculate the top margin of this match (roundIndex, matchIndex)
  const marginTop = getMarginTop(roundIndex, matchIndex);

  // ————————————————————————————
  // Find teams and winner
  const team1 = tournament.registered_teams?.find(
    (t) => t._id === match?.creating_team
  );
  const team2 = tournament.registered_teams?.find(
    (t) => t._id === match?.joining_team
  );
  const winnerTeam = tournament.registered_teams?.find(
    (t) => t._id === match?.winner_id
  );

  const handleMatchClick = () => {
    if (match?.match_id) {
      window.open(`/token/${match.match_id}`, '_blank');
    }
  };

  // CSS‐in‐JS style objects
  const styles = {
    matchBox: {
      backgroundColor: cardDark,
      borderRadius: "8px",
      padding: "4px 8px",
      position: "relative",
      minHeight: MATCH_HEIGHT,
      border: `1px solid ${cardVeryLight}`,
      transition: "all 0.2s ease",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      width: "100%",
      willChange: 'transform',
      transform: 'translateZ(0)',
    },
    player: {
      display: "flex",
      alignItems: "center",
      padding: "4px",
      borderRadius: "6px",
      marginBottom: "4px",
      backgroundColor: (props) =>
        props.isWinner ? `${doubtedBlue}22` : "transparent",
      border: (props) =>
        props.isWinner ? `1px solid ${doubtedBlue}` : `1px solid transparent`,
      opacity: (props) => (props.isLoser ? 0.5 : 1),
      transition: "all 0.2s ease",
    },
    playerName: {
      color: text,
      marginLeft: "8px",
      fontSize: "13px",
      fontWeight: (props) => (props.isWinner ? 600 : 400),
      flex: 1,
    },
    avatarGroup: {
      display: "flex",
      marginRight: "8px",
      "& > *:not(:first-of-type)": {
        marginLeft: "-6px",
      },
    },
    avatarWrapper: {
      border: `2px solid ${cardDark}`,
      borderRadius: "50%",
      transition: "transform 0.2s ease",
      "&:hover": {
        transform: "translateY(-2px)",
        zIndex: 2,
      },
    },
    vsText: {
      color: offWhite,
      fontSize: "11px",
      textAlign: "center",
      margin: "2px 0",
      opacity: 0.7,
    },
    winnerBadge: {
      position: "absolute",
      right: "10px",
      display: "flex",
      alignItems: "center",
      gap: "4px",
      color: yellow,
      fontSize: "14px",
    },
    connector: {
      position: "absolute",
      right: -ROUND_GAP,
      width: ROUND_GAP,
      height: "4px",
      backgroundColor: cardVeryLight,
      top: MATCH_HEIGHT / 2,
    },
    verticalConnector: {
      position: "absolute",
      right: -ROUND_GAP,
      width: "4px",
      backgroundColor: cardVeryLight,
      // For even-indexed matches, the vertical connector goes downward.
      // For odd-indexed matches, it goes upward.
      ...(matchIndex % 2 === 0
        ? {
            top: MATCH_HEIGHT / 2,
            // Vertical height is the difference between the margin of matchIndex+1 and matchIndex,
            // plus MATCH_HEIGHT/2
            height:
              getMarginTop(roundIndex, matchIndex + 1) -
              getMarginTop(roundIndex, matchIndex) +
              MATCH_HEIGHT / 2,
          }
        : {
            bottom: MATCH_HEIGHT / 2 + 2,
            height:
              getMarginTop(roundIndex, matchIndex) -
              getMarginTop(roundIndex, matchIndex - 1) +
              MATCH_HEIGHT / 2,
          }),
    },
    advanceArrow: {
      position: "absolute",
      right: -ROUND_GAP + 10,
      top: MATCH_HEIGHT / 2 - 12,
      color: doubtedBlue,
      fontSize: "24px",
      zIndex: 1,
    },
  };

  const TeamRow = ({ team }) => {
    if (!team) {
      return (
        <Box
          sx={{
            ...styles.player,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            height: "32px",
          }}
        >
          <Typography
            sx={{
              color: text,
              fontSize: "14px",
              margin: 0,
              textAlign: "center",
            }}
          >
            TBD
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          ...styles.player,
          isWinner: winnerTeam?._id === team._id,
          isLoser: winnerTeam && winnerTeam?._id !== team._id,
        }}
      >
        <Box sx={styles.avatarGroup}>
          {team.users?.map((user, index) => (
            <Box key={index} sx={styles.avatarWrapper}>
              <Avatar size={24} avatar={user.new_avatar} bgColor={cardVeryLight} />
            </Box>
          ))}
        </Box>
        <Typography
          sx={{
            ...styles.playerName,
            isWinner: winnerTeam?._id === team._id,
          }}
        >
          {team.name}
        </Typography>
        {winnerTeam?._id === team._id && (
          <Box sx={styles.winnerBadge}>
            <GiTrophy />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: marginTop,
        left: 0,
        right: 0,
        height: MATCH_HEIGHT,
        zIndex: roundIndex === maxRounds - 1 ? 2 : 1,
      }}
    >
      {/* The clickable match box */}
      <Box
        sx={{
          ...styles.matchBox,
          cursor: match?.match_id ? "pointer" : "default",
          "&:hover": match?.match_id
            ? {
                transform: "translateY(-2px)",
                boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                borderColor: doubtedBlue,
              }
            : {},
        }}
        onClick={handleMatchClick}
      >
        <TeamRow team={team1} />
        <Typography sx={styles.vsText}>vs</Typography>
        <TeamRow team={team2} />
      </Box>

      {/* Connectors to the next round */}
      {roundIndex < maxRounds - 1 && (
        <>
          <Box sx={styles.connector} />
          <Box sx={styles.verticalConnector} />
          {winnerTeam && (
            <FaLongArrowAltRight
              style={{
                ...styles.advanceArrow,
                top: MATCH_HEIGHT / 2 - 10,
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default MatchBox;
