import "./App.css"; 
import {
  Grid,
  CssBaseline,
  createTheme,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useLayoutEffect, useReducer, useState } from "react";
import Store, {
  initialStore,
  SET_CHAT_OPEN,
  SET_LEFT_RAIL_OPEN,
  SET_SEARCH_USER,
  storeReducer,
} from "./store/Store";
import { background, transition } from "./utils/themeContstants";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/app/Navbar";
import Home from "./components/home/Home";
import AuthCallback from "./components/app/AuthCallback";
import CurrentMatchHeader from "./components/app/CurrentMatchHeader";
import Verify from "./components/app/Verify";
import MatchPage from "./components/match/MatchPage";
import Profile from "./components/profile/Profile";
import Teams from "./components/teams/Teams";
import LinkedAccounts from "./components/connections/LinkedAccounts";
import Twitter from "./components/connections/Twitter";
import Twitch from "./components/connections/Twitch";
import Discord from "./components/connections/Discord";
import PayPal from "./components/connections/PayPal";
import History from "./components/history/History";
import Deposits from "./components/transactions/Deposits";
import Withdrawals from "./components/transactions/Withdrawals";
import Support from "./components/support/Support";
import Rules from "./components/support/Rules";
import Terms from "./components/support/Terms";
import Privacy from "./components/support/Privacy";
import FAQ from "./components/support/FAQ";
import Footer from "./components/app/Footer";
import Leaderboard from "./components/leaderboard/Leaderboard";
import VerifyStaff from "./components/app/VerifyStaff";
import { AccountTypeEnum } from "./utils/enums";
import StaffDashboard from "./components/staff/StaffDashboard";
import Disputes from "./components/staff/Disputes";
import StaffSearch from "./components/staff/StaffSearch";
import PendingWithdrawals from "./components/staff/PendingWithdrawals";
import ProfitStats from "./components/staff/ProfitStats";
import AlertMessage from "./components/app/AlertMessage";
import StaffPayPalTest from "./components/staff/StaffPayPalTest";
import PublicProfileModal from "./components/public_profile/PublicProfileModal";
import PayPalDepositRedirect from "./components/app/PayPalDepositRedirect";
import Receipt from "./components/app/Receipt";
import LeftRail from "./components/app/LeftRail";
import Locker from "./components/locker/Locker";
import Shop from "./components/shop/Shop";
import ShopItemDetail from "./components/shop/ShopItemDetail";
import Purchases from "./components/transactions/Purchases";
import Subscriptions from "./components/subscriptions/Subscriptions";
import ExpenseStats from "./components/staff/ExpenseStats";
import UserTips from "./components/staff/UserTips";
import UserTipsProfile from "./components/tip/UserTips";
import Games from "./components/games/Games";
import GameNav from "./components/app/GameNav";
import Matches from "./components/matches/Matches";
import AvailableMatches from "./components/matches/AvailableMatches";
import OngoingMatches from "./components/matches/OngoingMatches";
import BottomNavbar from "./components/mobile/BottomNavbar";
import MobileNotifications from "./components/mobile/notifications/MobileNotifications";
import CrateItemDetail from "./components/shop/CrateItemDetail";
import StaffACDashboard from "./components/staff/StaffACDashboard";
import ACUsers from "./components/staff/ac_dashboard/ACUsers";
import ACActivity from "./components/staff/ac_dashboard/ACActivity";
import StaffSecurityDashboard from "./components/staff/StaffSecurityDashboard";
import SecurityCheckUsers from "./components/staff/ac_dashboard/SecurityCheckUsers";
import PendingChargebacks from "./components/staff/PendingChargebacks";
import MapsDashboard from "./components/staff/MapsDashboard";
import UserACFlags from "./components/staff/UserACFlags";
import Crates from "./components/shop/Crates";
import ShopCoins from "./components/shop/ShopCoins";
import ShopOther from "./components/shop/ShopOther";
import ShopAvatars from "./components/shop/ShopAvatars";
import StaffList from "./components/staff/StaffList";
import StaffListUsers from "./components/staff/StaffListUsers";
import ShopBP from "./components/shop/ShopBP";
import BattlePassItemDetail from "./components/shop/BattlePassItemDetail";
import ReferralsDashboard from "./components/staff/ReferralsDashboard";
import StaffStats from "./components/staff/StaffStats";
import FSUsers from "./components/staff/ac_dashboard/FSUsers";
import StaffFSDashboard from "./components/staff/StaffFSDashboard";
import ReferralStats from "./components/profile/ReferralStats";
import Tournaments from './components/tournaments/Tournaments';
import TournamentDetail from './components/tournaments/TournamentDetail';
import LoadingScreen from "./components/custom/LoadingScreen";

const App = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const isTablet = useMediaQuery("(max-width: 768px)");
  const location = useLocation();
  const materialTheme = createTheme({
    typography: {
      fontFamily: "Inter",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: Inter
          }
        `,
      },
    },
  });

  // state and reducers
  const [store, storeDispatch] = useReducer(storeReducer, initialStore);
  const [lastLocation, setLastLocation] = useState(null);
  const [showHeader, setShowHeader] = useState(true);
  const [profileOpen, setProfileOpen] = useState(false);
  const [showGameNav, setShowGameNav] = useState(true);

  // effects
  useEffect(() => {
    const body = document.body.style;
    body.backgroundColor = background;
  }, []);

  useEffect(() => {
    setLastLocation(location?.pathname);
  }, [location?.pathname]);

  useEffect(() => {
    if (!isDesktop) {
      storeDispatch({ type: SET_CHAT_OPEN, payload: false });
    }
  }, [isDesktop, location?.pathname]);

  useEffect(() => {
    if (!isDesktop) {
      storeDispatch({ type: SET_LEFT_RAIL_OPEN, payload: false });
    }
  }, [isDesktop, location?.pathname]);

  useEffect(() => {
    if (isDesktop) {
      if (location?.pathname?.split("/")[1] === "token") {
        setShowHeader(false);
        storeDispatch({ type: SET_LEFT_RAIL_OPEN, payload: false });
      } else {
        setShowHeader(true);
      }

      if (location?.pathname === "/") {
        storeDispatch({ type: SET_LEFT_RAIL_OPEN, payload: true });
      }
    }

    if (location?.pathname?.split("/")[1] === "token") {
      setShowHeader(false);
      setShowGameNav(false);
    } else {
      setShowHeader(true);
      setShowGameNav(true);
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (store?.searchUser) {
      setProfileOpen(true);
    } else {
      setProfileOpen(false);
    }
  }, [store?.searchUser]);

  // methods
  const ScrollToTop = () => {
    useLayoutEffect(() => {
      if (location.pathname) {
        if (lastLocation !== location.pathname) {
          window.scrollTo(0, 0);
          return;
        }
      }
    }, []);
  };

  // styles
  const styles = {
    container: {
      minHeight: "100vh",
      minWidth: "100%",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      overflowX: "hidden",
      overflowY: "auto",
    },
    innerContainer: {
      paddingTop: 6,
      transition: "all .2s ease-in-out",
      position: "relative",
    },
  };
  

  return (
    <Grid container sx={styles.container}>
      <ThemeProvider theme={materialTheme}>
        <CssBaseline />
        <Store store={store} dispatch={storeDispatch}>
          <AlertMessage />
          <Navbar />
          {/* {isDesktop && <GameNav hidden={showGameNav} />} */}
          {store?.user && isDesktop && <LeftRail />}
          {store?.user?.match_info?.match_id && showHeader && !isDesktop && (
            <CurrentMatchHeader />
          )}
          <PublicProfileModal
            open={profileOpen}
            onClose={() =>
              storeDispatch({ type: SET_SEARCH_USER, payload: null })
            }
          />
          {isTablet && <BottomNavbar />}

          <ScrollToTop />

          <Grid
            container
            sx={styles.innerContainer}
            id="inner-container"
            justifyContent="start"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid
              item
              sx={{
                width: !store?.user
                  ? 0
                  : isDesktop
                  ? store.leftRailOpen
                    ? 240
                    : 0
                  : 0,
                minWidth: !store?.user
                  ? 0
                  : isDesktop
                  ? store.leftRailOpen
                    ? 240
                    : 0
                  : 0,
                transition: transition,
              }}
              id="left-rail-placeholder"
            />

            <Grid
              item
              sx={{ flexGrow: 1, minHeight: "100vh", overflow: "hidden" }}
              id="app-container"
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid
                  item
                  sx={{ width: "100%", minHeight: "100vh" }}
                  id="child-app-container"
                >
                  <Routes>
                    <Route path="/" exact element={<Home />} />

                    <Route path="/login" element={<Home />} />
                    <Route path="/signup" element={<Home />} />

                    <Route path="/support" element={<Support />}>
                      <Route path="terms" element={<Terms />} />
                      <Route path="rules" element={<Rules />} />
                      <Route path="privacy" element={<Privacy />} />
                      <Route path="faq" element={<FAQ />} />
                    </Route>

                    <Route path="/leaderboard" element={<Leaderboard />} />
                    <Route path="/tournaments" element={<Tournaments />} />
                    <Route path="/tournaments/:id" element={<TournamentDetail />} />

                    {/* <Route path="/games" element={<Games />} /> */}

                    <Route
                      path="/third-party-signin"
                      element={<AuthCallback />}
                    />

                    <Route
                      path="/wagers"
                      element={<Matches game={"fortnite"} />}
                    >
                      <Route path="matches" element={<AvailableMatches />} />
                      <Route path="ongoing" element={<OngoingMatches />} />
                    </Route>

                    {/* <Route
                      path="/warzone"
                      element={<Matches game={"warzone"} />}
                    >
                      <Route path="matches" element={<AvailableMatches />} />
                      <Route path="ongoing" element={<OngoingMatches />} />
                    </Route> */}

                    {/* <Route path="/chess" element={<Matches game={"chess"} />}>
                      <Route path="matches" element={<AvailableMatches />} />
                      <Route path="ongoing" element={<OngoingMatches />} />
                    </Route> */}

                    {/* <Route path="/fifa" element={<Matches game={"fifa"} />}>
                      <Route path="matches" element={<AvailableMatches />} />
                      <Route path="ongoing" element={<OngoingMatches />} />
                    </Route> */}
                    {/* 
                    <Route
                      path="/clashroyale"
                      element={<Matches game={"clashroyale"} />}
                    >
                      <Route path="matches" element={<AvailableMatches />} />
                      <Route path="ongoing" element={<OngoingMatches />} />
                    </Route> */}

                    <Route
                      path="/staff"
                      element={
                        <VerifyStaff
                          role={[
                            AccountTypeEnum.MODERATOR,
                            AccountTypeEnum.SENIOR_MODERATOR,
                            AccountTypeEnum.JUNIOR_ADMIN,
                            AccountTypeEnum.ADMIN,
                            AccountTypeEnum.GOD,
                            AccountTypeEnum.ANTICHEAT,
                            AccountTypeEnum.SENIOR_ADMIN,
                          ]}
                        >
                          <StaffDashboard />
                        </VerifyStaff>
                      }
                    >
                      <Route
                        path="disputes"
                        element={
                          <VerifyStaff
                            role={[
                              AccountTypeEnum.MODERATOR,
                              AccountTypeEnum.SENIOR_MODERATOR,
                              AccountTypeEnum.JUNIOR_ADMIN,
                              AccountTypeEnum.ADMIN,
                              AccountTypeEnum.SENIOR_ADMIN,
                              AccountTypeEnum.GOD,
                              AccountTypeEnum.ANTICHEAT,
                            ]}
                          >
                            <Disputes />
                          </VerifyStaff>
                        }
                      />
                      <Route
                        path="search/:user_id"
                        element={
                          <VerifyStaff
                            role={[
                              AccountTypeEnum.SENIOR_MODERATOR,
                              AccountTypeEnum.JUNIOR_ADMIN,
                              AccountTypeEnum.ADMIN,
                              AccountTypeEnum.SENIOR_ADMIN,
                              AccountTypeEnum.GOD,
                              AccountTypeEnum.ANTICHEAT,
                            ]}
                          >
                            <StaffSearch />
                          </VerifyStaff>
                        }
                      />
                      <Route
                        path="search"
                        element={
                          <VerifyStaff
                            role={[
                              AccountTypeEnum.SENIOR_MODERATOR,
                              AccountTypeEnum.JUNIOR_ADMIN,
                              AccountTypeEnum.ADMIN,
                              AccountTypeEnum.SENIOR_ADMIN,
                              AccountTypeEnum.GOD,
                              AccountTypeEnum.ANTICHEAT,
                            ]}
                          >
                            <StaffSearch />
                          </VerifyStaff>
                        }
                      />
                      <Route
                        path="withdrawals"
                        element={
                          <VerifyStaff role={[AccountTypeEnum.GOD]}>
                            <PendingWithdrawals />
                          </VerifyStaff>
                        }
                      />
                      <Route
                        path="chargebacks"
                        element={
                          <VerifyStaff role={[AccountTypeEnum.GOD]}>
                            <PendingChargebacks />
                          </VerifyStaff>
                        }
                      />
                      <Route
                        path="stats"
                        element={
                          <VerifyStaff role={[AccountTypeEnum.GOD]}>
                            <ProfitStats />
                          </VerifyStaff>
                        }
                      />
                      <Route
                        path="staffstats"
                        element={
                          <VerifyStaff
                            role={[
                              AccountTypeEnum.GOD,
                              AccountTypeEnum.SENIOR_ADMIN,
                            ]}
                          >
                            <StaffStats />
                          </VerifyStaff>
                        }
                      />
                      <Route
                        path="maps/dashboard"
                        element={
                          <VerifyStaff role={[AccountTypeEnum.GOD]}>
                            <MapsDashboard />
                          </VerifyStaff>
                        }
                      />
                      <Route
                        path="referrals/dashboard"
                        element={
                          <VerifyStaff
                            role={[
                              AccountTypeEnum.SENIOR_ADMIN,
                              AccountTypeEnum.GOD,
                            ]}
                          >
                            <ReferralsDashboard />
                          </VerifyStaff>
                        }
                      />
                      <Route
                        path="expenses"
                        element={
                          <VerifyStaff role={[AccountTypeEnum.GOD]}>
                            <ExpenseStats />
                          </VerifyStaff>
                        }
                      />

                      <Route
                        path="security"
                        element={
                          <VerifyStaff
                            role={[
                              AccountTypeEnum.GOD,
                              AccountTypeEnum.ANTICHEAT,
                              AccountTypeEnum.JUNIOR_ADMIN,
                              AccountTypeEnum.SENIOR_ADMIN,
                              AccountTypeEnum.ADMIN,
                            ]}
                          >
                            <StaffSecurityDashboard />
                          </VerifyStaff>
                        }
                      >
                        <Route
                          path="userstocheck"
                          element={
                            <VerifyStaff
                              role={[
                                AccountTypeEnum.GOD,
                                AccountTypeEnum.ANTICHEAT,
                                AccountTypeEnum.JUNIOR_ADMIN,
                                AccountTypeEnum.SENIOR_ADMIN,
                                AccountTypeEnum.ADMIN,
                              ]}
                            >
                              <SecurityCheckUsers />
                            </VerifyStaff>
                          }
                        />{" "}
                      </Route>
                      <Route
                        path="current"
                        element={
                          <VerifyStaff
                            role={[
                              AccountTypeEnum.GOD,
                              AccountTypeEnum.SENIOR_ADMIN,
                              AccountTypeEnum.ADMIN,
                            ]}
                          >
                            <StaffList />
                          </VerifyStaff>
                        }
                      >
                        <Route
                          path="list"
                          element={
                            <VerifyStaff
                              role={[
                                AccountTypeEnum.GOD,
                                AccountTypeEnum.SENIOR_ADMIN,
                                AccountTypeEnum.ADMIN,
                              ]}
                            >
                              <StaffListUsers />
                            </VerifyStaff>
                          }
                        />{" "}
                      </Route>

                      <Route
                        path="ac"
                        element={
                          <VerifyStaff
                            role={[
                              AccountTypeEnum.GOD,
                              AccountTypeEnum.ANTICHEAT,
                              AccountTypeEnum.SENIOR_ADMIN,
                            ]}
                          >
                            <StaffACDashboard />
                          </VerifyStaff>
                        }
                      >
                        <Route
                          path="users"
                          element={
                            <VerifyStaff
                              role={[
                                AccountTypeEnum.GOD,
                                AccountTypeEnum.ANTICHEAT,
                                AccountTypeEnum.SENIOR_ADMIN,
                              ]}
                            >
                              <ACUsers />
                            </VerifyStaff>
                          }
                        />
                        <Route
                          path="activity"
                          element={
                            <VerifyStaff
                              role={[
                                AccountTypeEnum.GOD,
                                AccountTypeEnum.ANTICHEAT,
                                AccountTypeEnum.SENIOR_ADMIN,
                              ]}
                            >
                              <ACActivity />
                            </VerifyStaff>
                          }
                        />
                        <Route
                          path="flags"
                          element={
                            <VerifyStaff
                              role={[
                                AccountTypeEnum.GOD,
                                AccountTypeEnum.ANTICHEAT,
                              ]}
                            >
                              <UserACFlags />
                            </VerifyStaff>
                          }
                        />
                      </Route>
                      <Route
                        path="fs"
                        element={
                          <VerifyStaff
                            role={[
                              AccountTypeEnum.GOD,
                              AccountTypeEnum.ANTICHEAT,
                              AccountTypeEnum.SENIOR_ADMIN,
                              AccountTypeEnum.ADMIN
                            ]}
                          >
                            <StaffFSDashboard />
                          </VerifyStaff>
                        }
                      >
                        <Route
                          path="users"
                          element={
                            <VerifyStaff
                              role={[
                                AccountTypeEnum.GOD,
                                AccountTypeEnum.ANTICHEAT,
                                AccountTypeEnum.SENIOR_ADMIN,
                                AccountTypeEnum.ADMIN
                              ]}
                            >
                              <FSUsers />
                            </VerifyStaff>
                          }
                        />
                      </Route>
                      <Route
                        path="paypal"
                        element={
                          <VerifyStaff role={[AccountTypeEnum.GOD]}>
                            <StaffPayPalTest />
                          </VerifyStaff>
                        }
                      />
                    </Route>

                    <Route
                      path="/token/:id"
                      element={
                        <Verify>
                          <MatchPage />
                        </Verify>
                      }
                    />

                    <Route
                      path="/locker"
                      element={
                        <Verify>
                          <Locker />
                        </Verify>
                      }
                    />

                    <Route path="/shop" element={<Shop />}>
                      <Route path="avatars" element={<ShopAvatars />} />
                      <Route path="crates" element={<Crates />} />
                      <Route path="coins" element={<ShopCoins />} />
                      <Route path="other" element={<ShopOther />} />
                      <Route path="bp" element={<ShopBP />} />
                      <Route
                        path="crates/godlike"
                        element={<CrateItemDetail />}
                      />
                      <Route
                        path="crates/exotic"
                        element={<CrateItemDetail />}
                      />
                      <Route path="crates/rare" element={<CrateItemDetail />} />
                      <Route
                        path="crates/emoji"
                        element={<CrateItemDetail />}
                      />
                         <Route
                        path="crates/emote"
                        element={<CrateItemDetail />}
                      />
                      <Route path="shop-item" element={<ShopItemDetail />} />

                      <Route
                        path="battle_pass"
                        element={<BattlePassItemDetail />}
                      />
                    </Route>

                    <Route
                      path="/notifications"
                      element={
                        <Verify>
                          <MobileNotifications />
                        </Verify>
                      }
                    />

                    <Route
                      path="/profile"
                      element={
                        <Verify>
                          <Profile />
                        </Verify>
                      }
                    >
                      <Route
                        path="teams"
                        element={
                          <Verify>
                            <Teams />
                          </Verify>
                        }
                      />
                      <Route
                        path="accounts"
                        element={
                          <Verify>
                            <LinkedAccounts />
                          </Verify>
                        }
                      />
                      <Route
                        path="history"
                        element={
                          <Verify>
                            <History />
                          </Verify>
                        }
                      />
                      <Route
                        path="deposits"
                        element={
                          <Verify>
                            <Deposits />
                          </Verify>
                        }
                      />
                      <Route
                        path="withdrawals"
                        element={
                          <Verify>
                            <Withdrawals />
                          </Verify>
                        }
                      />
                      <Route
                        path="tips"
                        element={
                          <Verify>
                            <UserTipsProfile />
                          </Verify>
                        }
                      />
                      <Route
                        path="purchases"
                        element={
                          <Verify>
                            <Purchases />
                          </Verify>
                        }
                      />

                      <Route
                        path="subscriptions"
                        element={
                          <Verify>
                            <Subscriptions />
                          </Verify>
                        }
                      />

                      <Route
                        path="referrals"
                        element={
                          <Verify>
                            <ReferralStats />
                          </Verify>
                        }
                      />

                      <Route
                        path="referrals/:referral_name"
                        element={
                          <Verify>
                            <ReferralStats />
                          </Verify>
                        }
                      />
                    </Route>

                    <Route
                      path="/twitter"
                      element={
                        <Verify>
                          <Twitter />
                        </Verify>
                      }
                    />
                    <Route
                      path="/twitch"
                      element={
                        <Verify>
                          <Twitch />
                        </Verify>
                      }
                    />
                    <Route
                      path="/discord"
                      element={
                        <Verify>
                          <Discord />
                        </Verify>
                      }
                    />
                    {/* <Route
                      path="/paypal"
                      element={
                        <Verify>
                          <PayPal />
                        </Verify>
                      }
                    />
                    <Route
                      path="/paypal-deposit"
                      element={
                        <Verify>
                          <PayPalDepositRedirect />
                        </Verify>
                      }
                    /> */}
                    <Route path="/receipt/:id" element={<Receipt />} />
                  </Routes>
                </Grid>

                {location?.pathname?.split("/")[1] === "token" ? null : (
                  <Grid item sx={{ minWidth: "100%", width: "100%" }}>
                    <Footer />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* 
            {isDesktop && showGameNav && (
              <Grid
                item
                id="game-nav-placeholder"
                sx={{ width: 64, height: "100vh", minHeight: "100vh" }}
              />
            )} */}
          </Grid>
        </Store>
      </ThemeProvider>
    </Grid>
  );
};

export default App;
